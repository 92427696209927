import { media } from '@mf/common/utils/styles/index';
import styled from 'styled-components';
import { FsModal } from '@printi/ds-offset-react-core';

export const Modal = styled(FsModal)`
  z-index: 3000; //TODO
`;

export const ModalContent = styled.div`
  width: 100%;
  display: flex;
  padding: var(--spacing-5x);
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: var(--spacing-3x);
  width: 100%;

  ${media.lessThan('mobile')} {
    padding: var(--spacing-none);
  }
`;
