import styled from 'styled-components';
import { media } from '@mf/common/utils/styles/index';

interface HeroImageProps {
  hideOnMobile?: boolean;
  hideOnTouch?: boolean;
}

export const HeroImage = styled.div<HeroImageProps>`
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
  display: block;
  position: relative;

  ${media.lessThan('mobile')} {
    flex: auto;
    height: 404px;

    display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'block')};
  }

  ${media.between('mobile', 'touch')} {
    flex: auto;
    height: 427px;

    display: ${({ hideOnTouch }) => (hideOnTouch ? 'none' : 'block')};
  }
`;
