import {
  ButtonSize,
  FsButton,
  FsInputText,
  HeadingTag,
  ModalType,
} from '@printi/ds-offset-react-core';
import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useMutation } from '@tanstack/react-query';
import bffZipCode from '@mf/checkout/repositories/bff/zip-code';
import { useProductStore } from '@mf/common/store/product/product.store';
import productService from '@/services/product';
// eslint-disable-next-line import/order
import * as S from './ModalProductFreight.styles';

interface ModalProductFreightProps {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  productId: string;
}
export const ModalProductFreight = ({
  isOpen,
  setOpen,
  productId,
}: ModalProductFreightProps) => {
  const [cep, setCep] = useState<string>('');
  const [cepError, setCepError] = useState<string>('');
  const auth = {
    baseUrl: process.env.BFF_API_BASE_URL,
  };
  const { getFreightOptions } = productService(auth);
  const { getZipCode } = bffZipCode(auth);
  const {
    printModelCombination,
    quantity,
    setFreightOptions,
    setAddress,
    setIsLoadingShipping,
    zipCode,
    setZipCode,
  } = useProductStore();

  const { mutate, isLoading } = useMutation(getFreightOptions, {
    onSuccess: async (data) => {
      if (!data.length) {
        setCepError(
          'Este produto não está disponível para entrega no CEP informado. Digite um novo CEP.',
        );
        return;
      }
      const address = await getZipCode({ zipCode: cep });
      if (address.zip_code) {
        window.dataLayer.push({
          event: 'genericEvent',
          event_name: 'shipping_simulation_completed',
          event_parameter_1: address.city,
          event_parameter_2: address.state,
          event_parameter_3: address.zip_code,
        });
        setAddress(address);
      }
      setCepError('');
      setFreightOptions(data);
      setOpen(false);
    },
    onError: () => {
      setCepError(
        'Opções não encontradas para o CEP informado. Por favor, verifique o CEP fornecido.',
      );
    },
  });
  const handleCep = (e: FormEvent<HTMLDivElement>) => {
    const value = (e.target as HTMLInputElement).value;
    setCep(value);
  };

  const onConfirmCep = () => {
    const qnt = quantity.quantity;
    if (!printModelCombination?.id || !qnt) {
      return;
    }
    const product_parts = printModelCombination?.parts || [];
    const payload = {
      zip_code: cep,
      quantity: qnt,
      product_parts,
    };
    setZipCode(cep);
    mutate({ final_product_id: productId, payload });
  };

  useEffect(() => {
    if (!cep) {
      return;
    }

    onConfirmCep();
    // eslint-disable-next-line
  }, [quantity]);

  useEffect(() => {
    setIsLoadingShipping(isLoading);
  }, [isLoading, setIsLoadingShipping]);

  useEffect(() => {
    if (!zipCode) {
      return;
    }

    setCep(zipCode);
  }, [zipCode]);

  return (
    <S.Modal
      heading="Prazo e valor de entrega"
      headingProps={{ tag: HeadingTag.SPAN }}
      isOpen={isOpen}
      type={ModalType.Slot}
      onClose={() => {
        setOpen(false);
        setCepError('');
      }}
      fixedBarActionSlot={
        <FsButton
          size={ButtonSize.LG}
          onClick={onConfirmCep}
          loading={isLoading}
          disabled={cep.length !== 9}
        >
          Simular
        </FsButton>
      }
    >
      <S.ModalContent>
        <FsInputText
          maskType="cep"
          label="CEP"
          placeholder="Digite seu CEP"
          onChange={handleCep}
          onFocus={() => {
            window.dataLayer.push({
              event: 'genericEvent',
              event_name: 'insert_zip_code',
            });
          }}
          value={cep}
          error={!!cepError}
          supportText={cepError}
        />
      </S.ModalContent>
    </S.Modal>
  );
};
