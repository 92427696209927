import Image from 'next/image';
import { TCmsMedia } from 'repositories/defaults/types';
import * as S from './CategoryImage.styles';

export const CategoryImage = ({ image }: { image: TCmsMedia | null }) => {
  return (
    <S.HeroImage key={image?.id}>
      <Image
        layout="fill"
        objectFit="contain"
        alt={image?.attributes.alternativeText || ''}
        src={image?.attributes.url || ''}
        fetchPriority="high"
        loading="eager"
      />
    </S.HeroImage>
  );
};
