import { useProductStore } from '@mf/common/store/product/product.store';
import { FormEvent } from 'react';
import { TCustomFormat, TCustomPage } from '@/services/product/types';

interface Props {
  customFormat?: TCustomFormat;
  customPage?: TCustomPage;
}

export const useCustomProductPropertyHooks = ({
  customFormat,
  customPage,
}: Props) => {
  const {
    setCustomPropertiesErrors,
    customPropertiesErrors,
    setCustomFormatValue,
    customFormatValue,
    setCustomPageValue,
  } = useProductStore();

  const isMultipleOf = (num: number, multipleOf: number) => {
    if (multipleOf === 0) {
      return false;
    }
    return num % multipleOf === 0;
  };

  const validHeight = (value: number) => {
    if (!customFormat?.height.min || !customFormat?.height.max) {
      return;
    }

    let errors = { ...customPropertiesErrors };
    if (value < customFormat.height.min || value > customFormat.height.max) {
      errors = {
        ...customPropertiesErrors,
        height: {
          error: true,
          text: 'Insira uma altura válida',
        },
      };
    } else {
      errors = {
        ...customPropertiesErrors,
        height: {
          error: false,
          text: '',
        },
      };
    }

    setCustomPropertiesErrors(errors);
  };

  const validWidth = (value: number) => {
    if (!customFormat?.width.min || !customFormat?.width.max) {
      return;
    }

    let errors = { ...customPropertiesErrors };
    if (value < customFormat.width.min || value > customFormat.width.max) {
      errors = {
        ...customPropertiesErrors,
        width: {
          error: true,
          text: 'Insira uma largura válida',
        },
      };
    } else {
      errors = {
        ...customPropertiesErrors,
        width: {
          error: false,
          text: '',
        },
      };
    }

    setCustomPropertiesErrors(errors);
  };

  const validPages = (value: number) => {
    if (!customPage?.min || !customPage.max) {
      return;
    }

    let errors = { ...customPropertiesErrors };
    if (value < customPage.min || value > customPage.max) {
      errors = {
        ...customPropertiesErrors,
        pages: {
          error: true,
          text: 'Insira uma quantidade válida',
        },
      };
    } else if (
      customPage?.multiple &&
      !isMultipleOf(value, customPage?.multiple)
    ) {
      errors = {
        ...customPropertiesErrors,
        pages: {
          error: true,
          text: `A quantidade deve ser um número múltiplo de ${customPage.multiple}`,
        },
      };
    } else {
      errors = {
        ...customPropertiesErrors,
        pages: {
          error: false,
          text: '',
        },
      };
    }

    setCustomPropertiesErrors(errors);
  };

  const handleChangeValues = (
    e: FormEvent<HTMLDivElement>,
    type: 'width' | 'height' | 'pages',
  ) => {
    let value = parseFloat((e.target as HTMLInputElement).value);

    if (Number.isNaN(value)) {
      value = 0;
    }

    if (type === 'width') {
      setCustomFormatValue({
        ...customFormatValue,
        isUsed: true,
        width: value,
      });

      validWidth(value);
    } else if (type === 'height') {
      setCustomFormatValue({
        ...customFormatValue,
        isUsed: true,
        height: value,
      });

      validHeight(value);
    } else if (type === 'pages') {
      setCustomPageValue({
        isUsed: true,
        pages: value,
      });

      validPages(value);
    }
  };

  return {
    validHeight,
    validWidth,
    validPages,
    handleChangeValues,
    isMultipleOf,
  };
};
