import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { ProductStore, Quantity } from './product.types';

interface RQuantity {
  quantity: Quantity;
}

export const useProductStore = create<ProductStore>()(
  persist(
    (set) => ({
      parts: [],
      setParts: (parts) => set({ parts }),

      quantity: { quantity: 0, price: 0, unit_price: 0, token: '' },
      updateQuantity: (item: Quantity) =>
        set((state: RQuantity) => ({
          quantity: { ...state.quantity, ...item },
        })),

      customCombinations: {},
      addCustomCombination: (item) =>
        set((state) => ({
          customCombinations: { ...state.customCombinations, ...item },
        })),

      printModelCombination: null,
      setPrintModelCombination: (printModelCombination) =>
        set({ printModelCombination }),

      customCombination: {
        id: 'custom',
        name: 'Configure do seu jeito',
        is_visible: 1,
        parts: [],
      },
      setCustomCombination: (customCombination) => set({ customCombination }),

      currentStep: 0,
      setCurrentStep: (currentStep) => set({ currentStep }),

      stepsOption: [],
      setStepsOption: (stepsOption) => set({ stepsOption }),

      freightOptions: [],
      setFreightOptions: (freightOptions) => set({ freightOptions }),

      address: null,
      setAddress: (address) => set({ address }),

      customPageValue: { isUsed: false, pages: 0 },
      setCustomPageValue: (customPageValue) => set({ customPageValue }),

      customFormatValue: { isUsed: false, width: 0, height: 0 },
      setCustomFormatValue: (customFormatValue) => set({ customFormatValue }),

      customPropertiesErrors: {
        width: {
          error: false,
          text: '',
        },
        height: {
          error: false,
          text: '',
        },
        pages: {
          error: false,
          text: '',
        },
      },
      setCustomPropertiesErrors: (customPropertiesErrors) =>
        set({ customPropertiesErrors }),

      invisiblePartsOptions: null,
      setInvisiblePartsOptions: (invisiblePartsOptions) =>
        set({ invisiblePartsOptions }),

      quantitiesOptions: [],
      setQuantitiesOptions: (quantitiesOptions) => set({ quantitiesOptions }),

      toastOpen: false,
      setToastOpen: (toastOpen) => set({ toastOpen }),

      popoverOpen: false,
      setPopoverOpen: (popoverOpen) => set({ popoverOpen }),

      openConfig: false,
      setOpenConfig: (openConfig) => set({ openConfig }),

      isLoadingShipping: false,
      setIsLoadingShipping: (isLoadingShipping) => set({ isLoadingShipping }),

      zipCode: '',
      setZipCode: (zipCode) => set({ zipCode }),

      isSingleFormat: false,
      setIsSingleFormat: (isSingleFormat) => set({ isSingleFormat }),
    }),
    {
      name: 'product-control',
      partialize: (state) => ({
        customCombinations: state.customCombinations,
        zipCode: state.zipCode,
      }),
    },
  ),
);
