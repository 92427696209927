import { Dispatch, SetStateAction, useMemo } from 'react';
import {
  ButtonGroupItemVariant,
  ButtonGroupOrientation,
  ButtonSize,
  CardCheckoutType,
  DrawerPositions,
  DrawerSizes,
  FsButtonGroupItem,
  FsCardCheckout,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import { useLayout } from '@mf/common/components/Media/Layout';
import { useRouter } from 'next/navigation';
import { useCartStore } from '@mf/common/store/cart';
import Image from 'next/image';
import { CarouselRelatedProducts } from '../CarouselRelatedProducts';
import * as S from './DrawerCartConfirmation.styles';

interface DrawerCartConfirmationProps {
  isOpen: boolean;
  setOpenConfig: Dispatch<SetStateAction<boolean>>;
  productSlug: string;
}

export const DrawerCartConfirmation = ({
  isOpen,
  setOpenConfig,
  productSlug,
}: DrawerCartConfirmationProps) => {
  const { isMobile } = useLayout();
  const { push } = useRouter();
  const { cartItems, relatedProductsByProduct } = useCartStore();

  const lastItem = useMemo(() => cartItems[cartItems.length - 1], [cartItems]);
  const defaultImg =
    'https://d1br4h274rc9sc.cloudfront.net/content/Screenshot_2024_05_22_at_16_02_34_e5a79bd368.png';
  const hasRelatedProducts = !!relatedProductsByProduct[productSlug]?.length;

  return (
    <S.StyledDrawer
      className="drawer-base-config"
      heading="Confirmação do carrinho"
      navBarHeadingProps={{
        tag: HeadingTag.H2,
      }}
      isOpen={isOpen}
      size={DrawerSizes.LG}
      position={DrawerPositions.Right}
      showNavBar
      showFixedBar
      fixedBarProps={{
        actionSlot: (
          <S.ButtonGroup
            className="drawer-base-config__button-group"
            size={isMobile ? ButtonSize.SM : ButtonSize.LG}
            orientation={ButtonGroupOrientation.Horizontal}
          >
            <FsButtonGroupItem
              onClick={() => {
                push('/produtos');
                window.dataLayer.push({
                  event: 'genericEvent',
                  event_name: 'go_to_products',
                });
              }}
              slot="secondary"
              variant={ButtonGroupItemVariant.Mini}
            >
              {isMobile ? 'Explorar mais' : 'Explorar mais produtos'}
            </FsButtonGroupItem>
            <FsButtonGroupItem
              slot="primary"
              aria-label="Save Button"
              onClick={() => {
                push('/carrinho');
                window.dataLayer.push({
                  event: 'genericEvent',
                  event_name: 'go_to_cart',
                });
              }}
            >
              Ver carrinho
            </FsButtonGroupItem>
          </S.ButtonGroup>
        ),
      }}
      onClose={() => setOpenConfig(false)}
      style={{ padding: 0 }}
    >
      <S.Content>
        {!!lastItem && (
          <FsCardCheckout
            key={lastItem?.slug}
            heading="Adicionado ao carrinho!"
            description={lastItem?.name}
            image={
              <Image
                src={lastItem?.banner?.images[0].src || defaultImg}
                alt="Placeholder"
                fill
              />
            }
            type={CardCheckoutType.Bag}
          />
        )}

        <S.TopSectionCardsWrapper>
          {hasRelatedProducts && (
            <CarouselRelatedProducts
              isDrawerCarousel
              heading="Você também pode gostar"
              cards={relatedProductsByProduct[productSlug] || []}
            />
          )}
        </S.TopSectionCardsWrapper>
      </S.Content>
    </S.StyledDrawer>
  );
};
