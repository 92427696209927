import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  DrawerPositions,
  DrawerSizes,
  FsButton,
  FsButtonGroup,
  FsButtonMini,
  FsHeading,
  FsParagraph,
  FsRadioButton,
  HeadingSize,
  HeadingTag,
  ListSelectType,
} from '@printi/ds-offset-react-core';
import {
  TInvisiblePartOptions,
  useProductStore,
} from '@mf/common/store/product';
import { useLayout } from '@mf/common/components/Media/Layout';
import isEqual from 'lodash/isEqual';
// eslint-disable-next-line import/order
import { ProductData } from '@/screens/main/Product';
// eslint-disable-next-line import/order
import { CardSelect } from '@/screens/main/Product/Configuration/CardSelect';
// eslint-disable-next-line import/order
import {
  ProducAttributeOptionType,
  TCustomFormat,
  TCustomPage,
  TPartsAttributes,
  TPartsAttributesOptions,
} from '@/services/product/types';
import { ProductPreview } from '../ProductPreview';
// eslint-disable-next-line import/order
import { CustomProductProperty } from '../CustomProductProperty';
// eslint-disable-next-line import/order
import * as S from './DrawerProductConfig.styles';
// eslint-disable-next-line import/order
import { useDrawerProductConfigHooks } from './DrawerProductConfig.hooks';

interface BlockedOptions {
  [partId: string]: {
    [attributeKey: string]: string[];
  };
}

export interface CustomProperties {
  [partId: string]: {
    customPage?: TCustomPage;
    customFormat?: TCustomFormat;
  };
}

interface DrawerProductConfigProps {
  isOpen: boolean;
  visibleAttributes: TPartsAttributes[];
  visibleAttributesOptions: TPartsAttributesOptions[][];
  data: ProductData;
  customProperties: CustomProperties;
}

type TAutoSteps = {
  [key: number]: boolean;
};

export const DrawerProductConfig = ({
  isOpen,
  visibleAttributes,
  visibleAttributesOptions,
  customProperties,
  data,
}: DrawerProductConfigProps) => {
  const [blockedOptions, setBlockedOptions] = useState<BlockedOptions>({});
  const [autoSteps, setAutoSteps] = useState<TAutoSteps>({});
  const [invisibleOptions, setInvisibleOptions] =
    useState<TInvisiblePartOptions | null>(null);
  const optionsRef = useRef<(HTMLDivElement | null)[]>([]);

  const {
    printModelCombination,
    addCustomCombination,
    setPrintModelCombination,
    currentStep,
    customCombination,
    setCurrentStep,
    stepsOption,
    setStepsOption,
    setCustomCombination,
    customPropertiesErrors,
    customFormatValue,
    customPageValue,
    setCustomPageValue,
    setCustomFormatValue,
    setPopoverOpen,
    setOpenConfig,
    invisiblePartsOptions,
    quantity,
    setToastOpen,
  } = useProductStore();

  const { isMobile } = useLayout();

  const {
    checkRulesAndGetBlocked,
    updateCustomCombination,
    removeOptionAttribute,
  } = useDrawerProductConfigHooks({ productId: data.id });

  const maxSteps = visibleAttributes?.length || 0;

  const cardsOptions = useMemo(() => {
    return visibleAttributesOptions[currentStep]?.filter(
      (option: ProducAttributeOptionType) => {
        const { partId, attribute, id } = option;

        if (partId && attribute) {
          // Verifica se a partId existe e se o attribute existe dentro dessa partId
          const blockedAttributes = blockedOptions[partId]?.[attribute];
          if (blockedAttributes) {
            return !blockedAttributes.includes(id);
          }
        }

        return true;
      },
    );
  }, [blockedOptions, visibleAttributesOptions, currentStep]);

  const clear = useCallback(() => {
    setBlockedOptions({});
    setStepsOption([]);
    setAutoSteps({});
    setInvisibleOptions(null);

    setCustomCombination({
      id: 'custom',
      name: 'Configure do seu jeito',
      is_visible: 1,
      parts: [],
    });
    setCustomPageValue({
      isUsed: false,
      pages: 0,
    });
    setCustomFormatValue({
      isUsed: false,
      width: 0,
      height: 0,
    });
    setCurrentStep(0);
  }, [
    setCurrentStep,
    setCustomCombination,
    setCustomFormatValue,
    setCustomPageValue,
    setStepsOption,
  ]);

  const onClose = () => {
    clear();
    setOpenConfig(false);
  };

  const blockedBackButton = useMemo(() => {
    if (!currentStep) {
      return true;
    }

    let blocked = true;
    const arrAutoSteps = Object.values(autoSteps);
    for (let i = 0; i < currentStep; i++) {
      if (typeof arrAutoSteps[i] !== 'undefined' && arrAutoSteps[i] === false) {
        blocked = false;
      }
    }
    return blocked;
  }, [currentStep, autoSteps]);

  const submitCustomCombination = useCallback(() => {
    if (!customCombination || !customCombination.parts.length) {
      return;
    }
    addCustomCombination({
      [data.slug]: customCombination,
    });
    setPrintModelCombination(customCombination);
    clear();
  }, [
    addCustomCombination,
    clear,
    customCombination,
    data.slug,
    setPrintModelCombination,
  ]);

  const handleNextStep = useCallback(
    (isAutoStep?: boolean) => {
      if (isAutoStep) {
        setAutoSteps((prev) => ({
          ...prev,
          [currentStep]: true,
        }));
      } else {
        setAutoSteps((prev) => ({
          ...prev,
          [currentStep]: false,
        }));
      }

      setCurrentStep(currentStep + 1);
    },
    [currentStep, setCurrentStep],
  );

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const countAllAutoOptions = Object.values(autoSteps).length;
    if (currentStep === maxSteps && countAllAutoOptions === maxSteps) {
      submitCustomCombination();
      setOpenConfig(false);
      setToastOpen(true);
      setPopoverOpen(true);
    }
  }, [
    currentStep,
    autoSteps,
    customCombination,
    isOpen,
    maxSteps,
    setOpenConfig,
    setPopoverOpen,
    setToastOpen,
    submitCustomCombination,
  ]);

  const handlePrevStep = (step?: number) => {
    const currentPartId = stepsOption[currentStep]?.partId;
    const currentAttribute = stepsOption[currentStep]?.attribute;

    if (currentPartId && currentAttribute) {
      removeOptionAttribute({
        partId: currentPartId,
        attribute: currentAttribute,
      });
    }

    const applicabeStep = step || currentStep;
    const backStep = applicabeStep - 1;

    if (currentStep) {
      setCurrentStep(applicabeStep - 1);
    }

    if (currentStep && autoSteps[backStep]) {
      handlePrevStep(backStep);
    }
  };

  const handleSelectOption = useCallback(
    (option: ProducAttributeOptionType) => {
      const combination = {
        [currentStep]: option,
      };

      if (option.id !== 'custom_format' && option.id !== 'custom_page') {
        updateCustomCombination({
          partId: option.partId || '',
          newOptions: {
            [option.attribute || '']: option.id,
          },
        });
      }

      setStepsOption({
        ...stepsOption,
        ...combination,
      });
    },
    [currentStep, setStepsOption, stepsOption, updateCustomCombination],
  );

  const checkDefaultCombinationOptions = useCallback(() => {
    if (!isOpen) {
      return;
    }

    if (stepsOption[currentStep]?.id) {
      return;
    }

    const currentAttribute = visibleAttributes[currentStep]?.id;
    const currentPartId = visibleAttributes[currentStep]?.partId;

    if (!currentAttribute || !currentPartId) {
      return;
    }

    const part = printModelCombination?.parts.find(
      (part) => part.id === currentPartId,
    );

    const firstOption = visibleAttributesOptions[currentStep][0];
    if (!part) {
      if (firstOption) {
        handleSelectOption(firstOption);
      }
      return;
    }

    const defaultCombinationOption =
      part.options[currentAttribute as keyof typeof part.options];

    if (defaultCombinationOption) {
      const option = cardsOptions.find(
        (opt) => opt.id === defaultCombinationOption,
      );

      if (option) {
        handleSelectOption(option);
      } else if (cardsOptions[0]) {
        handleSelectOption(cardsOptions[0]);
      }
    }
    // eslint-disable-next-line
  }, [
    currentStep,
    printModelCombination?.parts,
    visibleAttributes,
    visibleAttributesOptions,
    isOpen,
  ]);

  const getDisabledNextStep = useCallback(() => {
    const currentAttribute = visibleAttributes[currentStep]?.id;
    const currentPartId = visibleAttributes[currentStep]?.partId;

    if (!stepsOption[currentStep]?.id && !!cardsOptions?.length) {
      return true;
    } else if (stepsOption[currentStep]?.id === 'custom_format') {
      if (
        customPropertiesErrors.width.error ||
        customPropertiesErrors.height.error
      ) {
        return true;
      }

      if (customFormatValue.height < 1 || customFormatValue.width < 1) {
        return true;
      }
    } else if (stepsOption[currentStep]?.id === 'custom_page') {
      if (customPropertiesErrors.pages.error) {
        return true;
      }

      if (customPageValue.pages < 1) {
        return true;
      }
    } else if (
      ['format', 'page'].includes(currentAttribute) &&
      currentPartId &&
      !cardsOptions?.length
    ) {
      const customProperty = customProperties[currentPartId];

      if (customProperty && currentAttribute === 'format') {
        const customFormat = customProperty.customFormat;

        if (customFormat?.allow) {
          return true;
        }
      }

      if (customProperty && currentAttribute === 'page') {
        const customPage = customProperty.customPage;

        if (customPage?.allow) {
          return true;
        }
      }
    }

    return false;
  }, [
    customPropertiesErrors,
    stepsOption,
    currentStep,
    customPageValue,
    cardsOptions?.length,
    customFormatValue.width,
    customFormatValue.height,
    customProperties,
    visibleAttributes,
  ]);

  useEffect(() => {
    setBlockedOptions(checkRulesAndGetBlocked());
  }, [customCombination, checkRulesAndGetBlocked]);

  useEffect(() => {
    const newFilteredOptions: TInvisiblePartOptions = {};

    for (const key in invisiblePartsOptions) {
      if (Object.prototype.hasOwnProperty.call(invisiblePartsOptions, key)) {
        newFilteredOptions[key] = {};

        // Itera sobre as chaves internas de cada parte
        for (const subKey in invisiblePartsOptions[key]) {
          if (
            Object.prototype.hasOwnProperty.call(
              invisiblePartsOptions[key],
              subKey,
            )
          ) {
            const options = invisiblePartsOptions[key][subKey];

            // Filtra os valores que não estão presentes em `blockedOptions`
            if (blockedOptions[key]) {
              const filtered = options.filter(
                (option) => !blockedOptions[key][subKey]?.includes(option.id),
              );

              newFilteredOptions[key][subKey] = filtered;
            }
          }
        }
      }
    }

    setInvisibleOptions(newFilteredOptions);
  }, [blockedOptions, invisiblePartsOptions]);

  const accumulateCustomCombinationByInvisible = useCallback(
    (invisible: TInvisiblePartOptions) => {
      // Fazer uma cópia profunda de customCombination
      const newCombination = {
        ...customCombination,
        parts: customCombination.parts.map((part) => ({
          ...part,
          options: { ...part.options },
        })),
      };

      Object.entries(invisible).forEach(([partId, attribs]) => {
        Object.entries(attribs).forEach(([attribute, options]) => {
          if (options && options.length === 1) {
            const partIndex = newCombination.parts.findIndex(
              (part) => part.id === partId,
            );

            if (partIndex !== -1) {
              // Fazer uma cópia profunda da parte específica
              newCombination.parts[partIndex] = {
                ...newCombination.parts[partIndex],
                options: {
                  ...newCombination.parts[partIndex].options,
                  [attribute]: options[0].id,
                },
              };
            } else {
              // Adicionar uma nova parte com cópia profunda
              newCombination.parts.push({
                id: partId,
                options: {
                  [attribute]: options[0].id,
                },
              });
            }
          }
        });
      });

      return newCombination;
    },
    [customCombination],
  );

  useEffect(() => {
    if (!invisibleOptions) {
      return;
    }

    const newCombination =
      accumulateCustomCombinationByInvisible(invisibleOptions);

    if (!isEqual(newCombination, customCombination)) {
      setCustomCombination(
        accumulateCustomCombinationByInvisible(invisibleOptions),
      );
    }
  }, [
    customCombination,
    invisibleOptions,
    accumulateCustomCombinationByInvisible,
    setCustomCombination,
  ]);

  useEffect(() => {
    checkDefaultCombinationOptions();
  }, [checkDefaultCombinationOptions]);

  useEffect(() => {
    if (!isOpen || !cardsOptions) {
      return;
    }
    if (cardsOptions?.length === 1) {
      if (
        typeof autoSteps[currentStep] === 'undefined' ||
        autoSteps[currentStep] === true
      ) {
        const singleOption = cardsOptions[0];
        handleSelectOption(singleOption);
        handleNextStep(true);
      }
    }
  }, [
    cardsOptions,
    isOpen,
    autoSteps,
    currentStep,
    handleNextStep,
    handleSelectOption,
  ]);

  const hasImageOptions = cardsOptions?.some((option) => option.image !== '');

  const renderOption = (option: ProducAttributeOptionType, index: number) => {
    if (hasImageOptions) {
      return (
        <CardSelect
          onClick={() => handleSelectOption(option)}
          selected={stepsOption[currentStep]?.id === option.id}
          key={option.id}
          value={option.id}
          heading={option.name}
          image={option.image || ''}
          onChange={() => {
            handleSelectOption(option);
          }}
          ref={(el) => {
            optionsRef.current[index] = el;
          }}
        />
      );
    }

    return (
      <S.ListSelect
        type={ListSelectType.Text}
        heading={option.name}
        selected={stepsOption[currentStep]?.id === option.id}
        onClick={() => handleSelectOption(option)}
        ref={(el) => {
          optionsRef.current[index] = el;
        }}
      >
        <FsRadioButton
          value={option.id}
          selected={stepsOption[currentStep]?.id === option.id}
          inputProps={{
            onChange: () => {
              handleSelectOption(option);
            },
          }}
        />
      </S.ListSelect>
    );
  };

  const renderCustomOption = () => {
    const currentAttribute = visibleAttributes[currentStep]?.id;
    const currentPartId = visibleAttributes[currentStep]?.partId;

    if (!currentPartId || !currentAttribute) {
      return;
    }

    if (['format', 'page'].includes(currentAttribute)) {
      const customProperty = customProperties[currentPartId];

      if (customProperty && currentAttribute === 'format') {
        const customFormat = customProperty.customFormat;

        if (customFormat?.allow) {
          return (
            <CustomProductProperty
              productId={data.id}
              partId={currentPartId}
              id="custom"
              heading="Formato Personalizado"
              attributeType="format"
              customFormat={customFormat}
              handleSelect={handleSelectOption}
              selected={stepsOption[currentStep]?.id === 'custom_format'}
            />
          );
        }
      } else if (customProperty && currentAttribute === 'page') {
        const customPage = customProperty.customPage;

        if (customPage?.allow) {
          return (
            <CustomProductProperty
              customPage={customPage}
              productId={data.id}
              id="custom"
              heading="Página Personalizada"
              attributeType="page"
              handleSelect={handleSelectOption}
              partId={currentPartId}
              selected={stepsOption[currentStep]?.id === 'custom_page'}
            />
          );
        }
      }
    }

    return <></>;
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen || !stepsOption[currentStep]?.id || !cardsOptions?.length) {
      return;
    }

    const index = cardsOptions.findIndex(
      (card) => card.id === stepsOption[currentStep].id,
    );

    if (index !== -1 && optionsRef.current[index]) {
      optionsRef.current[index]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [isOpen, stepsOption, currentStep, cardsOptions]);

  return (
    <S.StyledDrawer
      className="drawer-base-config"
      heading="Configuração de produto"
      isOpen={isOpen}
      size={DrawerSizes.XL}
      position={DrawerPositions.Right}
      navBarHeadingProps={{
        tag: HeadingTag.H2,
      }}
      showNavBar
      showFixedBar
      fixedNavBar={false}
      fixedBarProps={{
        headingProps: {
          tag: HeadingTag.H2,
        },
        showProgressBar: true,
        progressBarValue:
          ((currentStep + 1) / (visibleAttributes.length || 0)) * 100,
        contentSlot: (
          <>
            {!isMobile ? (
              <ProductPreview
                image={data?.image || ''}
                heading={data?.name || ''}
                description={`${quantity.quantity || 0} unid.`}
              />
            ) : (
              <> </>
            )}
          </>
        ),
        actionSlot: (
          <FsButtonGroup
            size={'lg'}
            style={{
              gap: 'var(--spacing-4x)',
            }}
          >
            <FsButton
              slot="primary"
              aria-label="Avançar"
              onClick={() => {
                if (currentStep <= maxSteps) {
                  handleNextStep();
                }
              }}
              disabled={getDisabledNextStep()}
            >
              {currentStep == maxSteps - 1 ? 'Concluir' : 'Avançar'}
            </FsButton>
            <FsButtonMini
              slot="secondary"
              aria-label="Voltar"
              onClick={() => handlePrevStep()}
              disabled={blockedBackButton}
            >
              Voltar
            </FsButtonMini>
          </FsButtonGroup>
        ),
      }}
      onClose={onClose}
      style={{ padding: 0 }}
    >
      {visibleAttributes &&
        visibleAttributesOptions &&
        currentStep !== maxSteps && (
          <S.Content>
            <S.Heading>
              <FsParagraph
                style={{
                  color: 'var(--text-description-color)',
                }}
              >
                {visibleAttributes[currentStep]?.partName}
              </FsParagraph>
              <FsHeading size={HeadingSize.SM} tag={HeadingTag.H3}>
                {visibleAttributes[currentStep]?.name}
              </FsHeading>
            </S.Heading>
            <S.Grid>
              {cardsOptions.map((option: ProducAttributeOptionType, index) =>
                renderOption(option, index),
              )}
            </S.Grid>
            {renderCustomOption()}
          </S.Content>
        )}
      {/* This step of personalization don't enter at V1 anymore, but still here for posteriority */}
      {/* {currentStep == maxSteps - 1 && (
        <S.Content>
          <FsTopSection
            heading={`${currentStep + 1}. Configurações Adicionais`}
            description=""
          />
          <FsAccordionSelectGroup>
            <FsAccordionSelect
              selected={false}
              expanded={true}
              id="recomendado"
              value={1}
              onChange={() => {}}
              label="Ajuste profissional"
              description="+ R$ 100,00"
            >
              <FsTag slot="tag" label="Recomendado" type={TagType.Promote} />
              <FsSwitch slot="input" />
              <FsAccordionSelectItem
                slot="content"
                type={AccordionSelectItemType.Paragraph}
              >
                <FsParagraph slot="paragraph">
                  Permita que nossos especialistas revisem e certifiquem-se de
                  que seu material está dentro dos parâmetros ideais para
                  impressão. Ao selecionar este serviço você concorda com os
                  termos e condições dispostos abaixo.
                </FsParagraph>
              </FsAccordionSelectItem>
              <FsAccordionSelectItem
                slot="content"
                type={AccordionSelectItemType.Slot}
              >
                <FsLink href="#" target={LinkTarget._blank}>
                  Termos e condições
                </FsLink>
              </FsAccordionSelectItem>
            </FsAccordionSelect>
            <FsAccordionSelect
              selected={false}
              expanded={true}
              id="recomendado"
              value={2}
              onChange={() => {}}
              label="Enviar arquivo aberto"
              description="+ R$ 50,00"
            >
              <FsSwitch slot="input" />
              <FsAccordionSelectItem
                slot="content"
                type={AccordionSelectItemType.Paragraph}
              >
                <FsParagraph slot="paragraph">
                  Escolha esta opção caso queira fazer o upload de um arquivo
                  editável no formato AI (Illustrator), INDD (InDesign), PSD
                  (Photoshop), CDR (Corel Draw) e JPG (Imagem)
                </FsParagraph>
              </FsAccordionSelectItem>
            </FsAccordionSelect>
          </FsAccordionSelectGroup>
        </S.Content>
      )} */}
    </S.StyledDrawer>
  );
};
