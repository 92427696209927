import { api } from '@mf/common/config/axios/serviceApi';
import { TAxiosDefaultConfig } from '@mf/common/repositories/aecomStrapiCms/defaults/types';
import { AxiosInstance } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { TGetZipCode, TZipCode } from './types';

const bffZipCode = ({ baseUrl, token }: TAxiosDefaultConfig) => {
  const apiBff: AxiosInstance = api({ baseUrl, token });

  const getZipCode = async ({
    zipCode,
  }: {
    zipCode: string;
  }): Promise<TZipCode> => {
    if (!zipCode) {
      return {
        city: '',
        neighborhood: '',
        state: '',
        street: '',
        zip_code: '',
      };
    }

    const { data }: TGetZipCode = await apiBff.get(
      `/v1/utils/zip-code/${zipCode}`,
    );

    return data;
  };

  const useZipCode = ({
    zipCode,
    id,
    currentQuery = 0,
  }: {
    zipCode: string;
    id?: string;
    currentQuery?: number;
  }) => {
    return useQuery({
      queryKey: ['zip-code', zipCode, id, currentQuery],
      queryFn: () => getZipCode({ zipCode }),
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 0,
      cacheTime: 0,
    });
  };

  return {
    getZipCode,
    useZipCode,
  };
};

export default bffZipCode;
