import styled from 'styled-components';
import { media } from '@mf/common/utils/styles/index';
import { FsButtonSecondary } from '@printi/ds-offset-react-core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 var(--spacing-2x);
  margin-top: -8px;

  ${media.greaterThan('touch')} {
    padding: 0 var(--spacing-5x);
    margin-top: var(--spacing-7x);
  }
`;

export const Hero = styled.div`
  max-width: 422px;

  ${media.greaterThan('touch')} {
    max-width: fit-content;
  }
`;

export const ButtonSecondary = styled(FsButtonSecondary)`
  margin-top: var(--spacing-5x);
`;

export const TopicsWrapper = styled.div`
  display: grid;
  width: 100%;
  margin-top: var(--spacing-5x);

  ${media.greaterThan('touch')} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

//Apenas para testes de QA. Remover quando for integrar com o Backend
export const ErrorTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  position: fixed;
  bottom: 40px;
  right: 16px;
  z-index: 2000;
  opacity: 0.1;

  p {
    font-size: 17px;
  }
`;
//------------------------------------------------remover até aqui
