'use client';

import {
  AccordionSelectItemType,
  FsAccordionSelect,
  FsAccordionSelectItem,
  FsInputText,
  FsRadioButton,
} from '@printi/ds-offset-react-core';
import { useProductStore } from '@mf/common/store/product/product.store';
// eslint-disable-next-line import/order
import { useEffect } from 'react';
// eslint-disable-next-line import/order
import {
  ProducAttributeOptionType,
  TCustomFormat,
  TCustomPage,
} from '@/services/product/types';
// eslint-disable-next-line import/order
import { useDrawerProductConfigHooks } from '../DrawerProductConfig/DrawerProductConfig.hooks';
// eslint-disable-next-line import/order
import { useCustomProductPropertyHooks } from './CustomProductProperty.hooks';
interface CustomProductPropertyProps {
  id: string;
  heading: string;
  attributeType: 'page' | 'format';
  partId: string;
  selected: boolean;
  handleSelect: (option: ProducAttributeOptionType) => void;
  customPage?: TCustomPage;
  customFormat?: TCustomFormat;
  productId: string;
}

export const CustomProductProperty = ({
  id,
  heading,
  attributeType,
  customPage,
  customFormat,
  partId,
  selected = false,
  handleSelect,
  productId,
}: CustomProductPropertyProps) => {
  const { customPropertiesErrors, customFormatValue, customPageValue } =
    useProductStore();

  const { handleChangeValues } = useCustomProductPropertyHooks({
    customFormat,
    customPage,
  });

  const { updateCustomCombination } = useDrawerProductConfigHooks({
    productId,
  });

  const getSupportText = (type?: string) => {
    if (customFormat) {
      if (type === 'width') {
        return `min: ${customFormat.width.min} , max: ${customFormat.width.max}`;
      } else if (type === 'height') {
        return `min: ${customFormat.height.min} , max: ${customFormat.height.max}`;
      }
    } else if (customPage) {
      return `min: ${customPage.min} , max: ${customPage.max}`;
    }

    return ``;
  };

  useEffect(() => {
    if (customFormatValue.width && customFormatValue.height) {
      updateCustomCombination({
        partId,
        newOptions: {
          format: `custom=${customFormatValue.width}x${customFormatValue.height}`,
        },
      });
    }
    // eslint-disable-next-line
  }, [customFormatValue.width, customFormatValue.height, partId]);

  useEffect(() => {
    if (customPageValue.pages) {
      updateCustomCombination({
        partId,
        newOptions: {
          page: `custom=${customPageValue.pages}`,
        },
      });
    }
    // eslint-disable-next-line
  }, [customPageValue.pages, partId]);

  return (
    <FsAccordionSelect
      alwaysExpanded
      expanded
      id={id}
      label={heading}
      style={{ marginTop: '24px', cursor: 'pointer' }}
      selected={selected}
      onClick={() => {
        handleSelect({
          id: `custom_${attributeType}`,
          name: 'Personalizado',
          attribute: attributeType,
          partId: partId,
        });
      }}
    >
      <FsRadioButton
        slot="input"
        style={{
          pointerEvents: 'none',
        }}
      />
      <FsAccordionSelectItem slot="content" type={AccordionSelectItemType.Slot}>
        {attributeType === 'format' && (
          <>
            <FsInputText
              id="largura"
              label="Largura (cm)"
              placeholder="Insira uma largura"
              type="number"
              inputProps={{
                type: 'number',
              }}
              supportText={
                customPropertiesErrors.width.text || getSupportText('width')
              }
              disabled={!selected}
              onChange={(e) => handleChangeValues(e, 'width')}
              error={customPropertiesErrors.width.error}
              value={customFormatValue.width.toString()}
            />
            <FsInputText
              id="altura"
              label="Altura (cm)"
              placeholder="Insira uma altura"
              type="number"
              inputProps={{
                type: 'number',
              }}
              supportText={
                customPropertiesErrors.height.text || getSupportText('height')
              }
              disabled={!selected}
              onChange={(e) => handleChangeValues(e, 'height')}
              error={customPropertiesErrors.height.error}
              value={customFormatValue.height.toString()}
            />
          </>
        )}
        {attributeType === 'page' && (
          <FsInputText
            id="paginas"
            label="Páginas"
            placeholder="Insira uma quantidade de páginas"
            inputProps={{
              type: 'number',
            }}
            type="number"
            supportText={customPropertiesErrors.pages.text || getSupportText()}
            disabled={!selected}
            onChange={(e) => handleChangeValues(e, 'pages')}
            error={customPropertiesErrors.pages.error}
            value={customPageValue.pages.toString()}
          />
        )}
      </FsAccordionSelectItem>
    </FsAccordionSelect>
  );
};
