import styled, { keyframes } from 'styled-components';
import { media } from '@mf/common/utils/styles/index';

const lettering = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
`;

export const Container = styled.div`
  display: flex;
  height: var(--size-15x);
  padding: var(--spacing-20x) var(--spacing-5x);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-5x);

  ${media.lessThan('touch')} {
    height: var(--size-10x);
    padding: var(--spacing-10x) var(--spacing-5x);
  }
`;

export const Lettering = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  height: inherit;
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;

  & p {
    display: flex;
    margin: 0;
    align-items: center;
    gap: var(--spacing-5x);
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: ${lettering} 24s linear infinite;

    & span {
      color: #ccc;
      text-align: center;
      font-family: var(--font-family-brand);
      font-size: 92px;
      line-height: 124%;
      letter-spacing: -1.84px;

      ${media.lessThan('touch')} {
        font-size: var(--size-8x);
      }
    }
  }
`;
