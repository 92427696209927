import styled from 'styled-components';
import { FsModal } from '@printi/ds-offset-react-core';

export const Modal = styled(FsModal)`
  z-index: 3000;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4x);
  width: 100%;

  .chip-group {
    display: flex;
    gap: var(--spacing-1x);
    flex-wrap: wrap;
  }
`;
