import styled from 'styled-components';
import { FsDrawer, FsListSelect } from '@printi/ds-offset-react-core';
import { media } from '@mf/common/utils/styles/index';

export const StyledDrawer = styled(FsDrawer)``;

export const Content = styled.div`
  width: 100%;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: var(--spacing-3x);

  .card-option {
    min-width: auto !important;
    max-width: 100% !important;
    width: 100% !important;
    @media only screen and (max-width: 1347px) {
      min-width: auto !important;
      max-width: 100% !important;
      width: 100% !important;
      gap: var(--spacing-1x);
    }
  }

  ${media.lessThan('mobile')} {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: var(--spacing-2x);
  }

  ${media.between('mobile', 'desktop')} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--spacing-2x);
  }
`;

export const ListSelect = styled(FsListSelect)`
  &:hover {
    cursor: pointer;
  }
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-1x);
  flex: 1 0 0;

  margin-bottom: var(--spacing-4x);
`;

export const ProgressWrapper = styled.div`
  position: relative;
  width: -webkit-fill-available;
`;
