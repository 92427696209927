import { media } from '@mf/common/utils/styles/index';
import styled from 'styled-components';
import { FsParagraph } from '@printi/ds-offset-react-core';
import { Container as CardProductSkeletonContainer } from '@mf/common/components/CardProductSkeleton/CardProductSkeleton.styles';
import { Content as ProductListContent } from '@components/main/ProductsList/ProductsList.styles';

export const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const HeroImages = styled.div`
  height: 560px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: stretch;
  gap: var(--spacing-1x);
  padding: 0 var(--spacing-5x);

  ${media.lessThan('mobile')} {
    padding: 0 var(--spacing-2x) var(--spacing-2_5x) var(--spacing-2x);
    justify-content: center;
    gap: var(--spacing-2x);
    flex-direction: column;
    height: 380px;
  }
`;

export const HeroDescription = styled.div`
  display: flex;

  flex-direction: column;
  align-items: flex-end;
  align-self: stretch;

  gap: var(--spacing-5x);

  padding: var(--spacing-5x);

  ${media.lessThan('touch')} {
    padding: var(--spacing-4x) var(--spacing-2x);
  }
`;

export const Paragraph = styled(FsParagraph)`
  width: 437px;

  ${media.lessThan('mobile')} {
    width: auto;
    align-self: stretch;
  }
`;

export const GridProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: var(--spacing-10x) var(--spacing-5x) var(--spacing-5x)
    var(--spacing-5x);

  ${media.lessThan('mobile')} {
    padding: var(--spacing-4x) var(--spacing-2x) var(--spacing-4x)
      var(--spacing-2x);
    align-self: stretch;
  }
`;

export const GridProductsCards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-6x);
  padding: var(--spacing-none) 0px;
  width: 100%;

  ${media.lessThan('touch')} {
    width: auto;
    justify-content: center;
    gap: var(--spacing-2x);
    align-self: stretch;
  }

  ${ProductListContent} {
    grid-template-columns: repeat(4, 1fr) !important;
    ${media.lessThan('touch')} {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }
`;

export const ProductsRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr) !important;
  width: 100%;
  gap: var(--spacing-4x);

  > div {
    cursor: pointer;
  }

  ${media.lessThan('touch')} {
    grid-template-columns: repeat(3, 1fr) !important;
    align-content: flex-start;
    gap: var(--spacing-2x);
    align-self: stretch;
  }

  ${media.lessThan('mobile')} {
    grid-template-columns: repeat(2, 1fr);
    row-gap: var(--spacing-4x);
  }
`;

export const Action = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;

  gap: var(--spacing-none);

  padding: var(--spacing-none);
  padding-top: var(--spacing-6x);
`;

export const BannerSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: var(--spacing-10x) var(--spacing-5x);
  img {
    object-fit: cover;
  }

  ${media.lessThan('mobile')} {
    padding: var(--spacing-5x) var(--spacing-2x);
    align-self: stretch;
  }

  ${media.lessThan('xs')} {
    #banner-related-category {
      span {
        font-size: var(--font-size-md);
      }
    }
  }
`;

export const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: var(--spacing-10x) var(--spacing-5x) var(--spacing-5x)
    var(--spacing-5x);

  ${media.lessThan('mobile')} {
    padding: var(--spacing-4x) var(--spacing-2x) var(--spacing-4x)
      var(--spacing-2x);
    align-self: stretch;
  }
`;

export const CardProductWrapper = styled.div`
  display: flex;
  min-width: 240px;
  width: 240px;
  cursor: pointer;
  ${media.greaterThan('touch')} {
    width: 322px;
  }

  ${CardProductSkeletonContainer} {
    width: 100%;
  }
`;
