import React from 'react';
import { FeedbackPageLetteringSeparator } from './FeedbackPageLetteringSeparator';
import * as S from './FeedbackPageLettering.styles';

export interface FeedbackPageLetteringProps {
  text: string;
}

export function FeedbackPageLettering({ text = '' }) {
  return (
    <S.Container>
      <S.Lettering>
        <p>
          {Array.from({ length: 5 }, (_, i) => (
            <React.Fragment key={i}>
              <span>{text}</span>
              <FeedbackPageLetteringSeparator />
            </React.Fragment>
          ))}
        </p>
      </S.Lettering>
    </S.Container>
  );
}
