import { media } from '@mf/common/utils/styles';
import styled from 'styled-components';
import { FsBreadcrumb } from '@printi/ds-offset-react-core';

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 0 var(--spacing-5x) var(--spacing-5x) var(--spacing-5x);

  ${media.lessThan('mobile')} {
    padding: 0 var(--spacing-2x) var(--spacing-4x) var(--spacing-2x);
    justify-content: center;
  }
`;

export const Content = styled.div`
  justify-content: space-between;
  display: flex;
  gap: var(--spacing-1x);
  padding-top: var(--spacing-10x);
  width: 100%;
  ${media.lessThan('mobile')} {
    gap: var(--spacing-2x);
    padding-top: var(--spacing-3x);
    justify-content: center;
    flex-direction: column;
  }
`;

export const Title = styled.div`
  display: flex;

  flex-direction: column;
  align-items: flex-start;

  gap: var(--spacing-1x);

  ${media.lessThan('mobile')} {
    align-self: stretch;
  }
`;

export const BreadCrumb = styled(FsBreadcrumb)``;

export const Description = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1 0 0;
  height: 76px;

  ${media.lessThan('mobile')} {
    height: auto;
  }

  p {
    width: 437px;

    ${media.lessThan('mobile')} {
      width: auto;
    }
  }
`;
