import {
  FsDivider,
  FsSkeleton,
  ListContentType,
  ListContentVariant,
} from '@printi/ds-offset-react-core';
import {
  TFreightOption,
  TZipCode,
} from '@mf/common/store/product/product.types';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { formatCurrency } from '@brazilian-utils/brazilian-utils';
import { useLayout } from '@mf/common/components/Media/Layout';
import { useProductStore } from '@mf/common/store/product/product.store';
import * as S from './FreightList.styles';

interface FreightListProps {
  address: TZipCode | null;
  freightOptions: TFreightOption[];
  setOpenFreight: Dispatch<SetStateAction<boolean>>;
}

export const FreightList = ({
  address,
  freightOptions,
  setOpenFreight,
}: FreightListProps) => {
  const { isMobile } = useLayout();
  const { isLoadingShipping } = useProductStore();
  const [textDescription, setTextDescription] = useState('');

  useEffect(() => {
    if (!address?.zip_code) {
      return;
    }

    setTextDescription(
      `${address?.city}, ${address?.state} - ${address?.zip_code}`,
    );
  }, [address]);

  const truncateText = (text: string): string => {
    const limit = 20;
    if (text.length > limit) {
      return text.substring(0, limit) + '...';
    }
    return text;
  };

  return (
    <S.Container>
      <S.ListContent
        type={isMobile ? ListContentType.Single : ListContentType.IconShape}
        variant={ListContentVariant.Line}
        lastItem={true}
        icon="DeliveryLine"
        showHeading={true}
        heading={`${address?.street}, ${address?.neighborhood}`}
        showButton={true}
        buttonLabel="Alterar CEP"
        onButtonClick={() => {
          setOpenFreight(true);
          window.dataLayer.push({
            event: 'genericEvent',
            event_name: 'start_shipping_simulation',
          });
        }}
        showLabelLeft={true}
        labelLeft={truncateText(textDescription)}
        showTag={false}
        labelRight=""
        description=""
      />
      {isLoadingShipping ? (
        <>
          {freightOptions.map((option) => (
            <FsSkeleton key={option.production_id} />
          ))}
        </>
      ) : (
        <>
          <FsDivider style={{ width: '100%' }} />
          {freightOptions.map((option, key) => (
            <S.ListContent
              heading={`${option.business_days} dias úteis`}
              key={option.production_id}
              type={ListContentType.Single}
              variant={ListContentVariant.Line}
              showButton={false}
              labelLeft={``}
              showTag={false}
              labelRight="a partir de "
              description={`R$ ${formatCurrency(option.price)}`}
              lastItem={key + 1 === freightOptions?.length}
            />
          ))}
        </>
      )}
    </S.Container>
  );
};
