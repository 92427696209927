/* eslint-disable import/order */
import Image from 'next/image';
import {
  AccordionSelectItemType,
  ButtonSecondarySize,
  FsAccordionSelectItem,
  FsButtonMini,
  FsButtonSecondary,
  FsDescription,
  FsHeading,
  FsParagraph,
  FsTag,
  FsTopSection,
  HeadingSize,
  HeadingTag,
  TagType,
} from '@printi/ds-offset-react-core';
import { TCombination, useProductStore } from '@mf/common/store/product';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLayout } from '@mf/common/components/Media/Layout';
// eslint-disable-next-line import/order
import { TParts } from '@mf/common/repositories/bff/product/types';
import iconCustomize from '@/assets/icon-customize.gif';
import {
  AccordionGroup,
  AccordionSelect,
  AccordionSelectItem,
  CardOutline,
  CardOutlineContent,
  ImageShape,
  RadioButton,
} from './PrintModels.styles';

interface PrintModelsProps {
  defaultCombinations?: TCombination[];
  productParts?: TParts[];
  slug: string;
}

export const PrintModels = ({
  defaultCombinations,
  productParts,
  slug,
}: PrintModelsProps) => {
  const [models, setModels] = useState<TCombination[]>([]);
  const modelsRef = useRef<(HTMLDivElement | null)[]>([]);

  const {
    printModelCombination,
    setPrintModelCombination,
    customCombinations,
    isSingleFormat,
    setOpenConfig,
  } = useProductStore();

  const { isMobile } = useLayout();

  const hasCustomCombination = customCombinations && customCombinations[slug];

  useEffect(() => {
    if (!printModelCombination || !models.length) {
      return;
    }
  }, [printModelCombination, models, modelsRef, isMobile]);

  const handleSelectPrintModel = (model: TCombination) => {
    setPrintModelCombination(model);
  };

  function removeCustomPrefix(input: string | number): string {
    let value: string | number = '';
    if (typeof input === 'number') {
      value = input.toString();
    } else {
      value = input;
    }
    if (value.includes('custom=')) {
      return value.replace('custom=', 'Personalizado ');
    }
    return value;
  }

  const getOptionName = useCallback(
    (id: string | number, partId: string) => {
      const part = productParts?.find((p) => p.id === partId);

      if (part) {
        const option = Object.values(part.attributes_options)
          .flat()
          .find((option) => option.id === id);

        if (option) {
          return option.name;
        } else if (removeCustomPrefix(id)) {
          return removeCustomPrefix(id);
        }
      }

      return null;
    },
    [productParts],
  );

  const getPartName = useCallback(
    (id: string) => {
      const part = productParts?.find((p) => p.id === id);

      if (part) {
        return part.name;
      }

      return 'Customização';
    },
    [productParts],
  );

  const customCombination = useMemo(() => {
    const custom = customCombinations[slug];

    if (!custom) {
      return null;
    }

    return custom;
  }, [customCombinations, slug]);

  const defaultModel = useMemo(() => {
    const economic = defaultCombinations?.find(
      (comb) => comb.name === 'Econômica',
    );

    if (economic) {
      return economic;
    }

    const bestSelling = defaultCombinations?.find(
      (comb) => comb.name === 'Mais Vendida',
    );

    if (bestSelling) {
      return bestSelling;
    }

    if (customCombination) {
      return customCombination;
    }

    return (defaultCombinations || [])[0] || null;
  }, [defaultCombinations, customCombination]);

  useEffect(() => {
    setPrintModelCombination(defaultModel);
  }, [defaultModel, setPrintModelCombination]);

  useEffect(() => {
    if (!defaultCombinations) {
      return;
    }

    if (!customCombination) {
      setModels(defaultCombinations);
    } else {
      setModels([customCombination, ...defaultCombinations]);
    }
  }, [customCombination, defaultCombinations]);

  return (
    <div
      style={{
        marginTop: isMobile ? 'var(--spacing-6x)' : '0px',
      }}
    >
      <FsTopSection
        headingProps={{
          size: HeadingSize.SM,
          tag: HeadingTag.SPAN,
        }}
        heading="Configurações do produto"
        showDescription={false}
      />

      {!isSingleFormat && !hasCustomCombination && (
        <CardOutline>
          <ImageShape>
            <Image
              src={iconCustomize.src}
              alt="Configure do seu jeito"
              width={64}
              height={64}
            />
          </ImageShape>
          <CardOutlineContent>
            <FsHeading size={HeadingSize['2XS']} tag={HeadingTag.H3}>
              Configure do seu jeito
            </FsHeading>
            <FsDescription>
              Escolha o formato, o tipo de impressão e outros detalhes para
              criar o produto perfeito para você.
            </FsDescription>
          </CardOutlineContent>
          <FsButtonMini onClick={() => setOpenConfig(true)}>
            Configurar
          </FsButtonMini>
        </CardOutline>
      )}

      <AccordionGroup>
        {(models.length ? models : defaultCombinations || []).map(
          (item, index) => {
            if (item.is_visible) {
              return (
                <AccordionSelect
                  expanded
                  alwaysExpanded
                  key={item.id}
                  id={item.id.toString()}
                  label={item.name}
                  description={''}
                  onClick={() => {
                    handleSelectPrintModel(item);
                  }}
                  selected={printModelCombination?.id === item.id}
                  ref={(el) => {
                    modelsRef.current[index] = el;
                  }}
                >
                  {item.id === 'custom' && (
                    <FsTag
                      slot="tag"
                      label="Personalizado"
                      type={TagType.Promote}
                    />
                  )}
                  <RadioButton
                    slot="input"
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                  {item?.parts.map((part) => {
                    const options = Object.values(part.options);
                    return (
                      <FsAccordionSelectItem
                        slot="content"
                        type={AccordionSelectItemType.Tag}
                        key={part.id}
                      >
                        <FsParagraph slot="paragraph">
                          {getPartName(part.id)}
                        </FsParagraph>
                        {options.map((option) => {
                          if (!option) {
                            return;
                          }
                          const name = getOptionName(option, part.id);
                          if (!name) {
                            return;
                          }
                          return (
                            <FsTag
                              slot="tags"
                              label={name}
                              type={TagType.Informative}
                              key={option}
                            />
                          );
                        })}
                      </FsAccordionSelectItem>
                    );
                  })}

                  {item.id === 'custom' && (
                    <AccordionSelectItem
                      slot="content"
                      type={AccordionSelectItemType.Slot}
                    >
                      <FsButtonSecondary
                        icon="EditLine"
                        size={
                          isMobile
                            ? ButtonSecondarySize.SM
                            : ButtonSecondarySize.LG
                        }
                        onClick={() => setOpenConfig(true)}
                      >
                        Editar
                      </FsButtonSecondary>
                    </AccordionSelectItem>
                  )}
                </AccordionSelect>
              );
            }
          },
        )}
      </AccordionGroup>
    </div>
  );
};
