'use client';

import {
  ButtonSecondarySize,
  FsTextContent,
  FsTopicIcon,
  HeadingTag,
  TextContentType,
} from '@printi/ds-offset-react-core';
import { useLayout } from '@mf/common/components/Media/Layout';
import Link from 'next/link';
import { FeedbackPageLettering } from '@/components/main/FeedbackPageLettering';
import { ErrorState } from '@/@types/error';
// eslint-disable-next-line import/order
import * as S from './NotFound.styles';

interface NotFoundScreenProps {
  errorState: ErrorState;
}

export function NotFoundScreen({ errorState }: NotFoundScreenProps) {
  const { isMobile } = useLayout();

  const getErrorContent = () => {
    switch (errorState) {
      case 'not_found':
        return {
          headingText: 'Página não encontrada',
          paragraphText:
            'A página que você está buscando não existe ou não foi encontrada.',
          errorText: '404',
          feedbackText: 'página não encontrada',
          buttonText: 'Ir para o início',
          buttonAction: () => (
            <Link href="/">
              <S.ButtonSecondary
                size={
                  isMobile ? ButtonSecondarySize.SM : ButtonSecondarySize.LG
                }
              >
                Ir para o início
              </S.ButtonSecondary>
            </Link>
          ),
        };
      case 'server_error':
        return {
          headingText: 'Erro interno do servidor',
          paragraphText:
            'Houve um problema no servidor ao processar a solicitação.',
          errorText: '500',
          feedbackText: 'erro interno do servidor',
          buttonText: 'Ir para o início',
          buttonAction: () => (
            <Link href="/">
              <S.ButtonSecondary
                size={
                  isMobile ? ButtonSecondarySize.SM : ButtonSecondarySize.LG
                }
              >
                Ir para o início
              </S.ButtonSecondary>
            </Link>
          ),
        };
      case 'unknown_error':
        return {
          headingText: 'Instabilidade por alto volume',
          paragraphText:
            'Parece que o site está agitado com muitas visitas agora. Tente atualizar a página.',
          errorText: 'Ops!',
          feedbackText: 'voltamos já',
          buttonText: 'Atualizar página',
          buttonAction: () => (
            <S.ButtonSecondary
              size={isMobile ? ButtonSecondarySize.SM : ButtonSecondarySize.LG}
              onClick={() => window.location.reload()}
            >
              Atualizar página
            </S.ButtonSecondary>
          ),
        };
      default:
        return {
          headingText: 'Instabilidade por alto volume',
          paragraphText:
            'Parece que o site está agitado com muitas visitas agora. Tente atualizar a página.',
          errorText: 'Ops!',
          feedbackText: 'voltamos já',
          buttonText: 'Atualizar página',
          buttonAction: () => (
            <S.ButtonSecondary
              size={isMobile ? ButtonSecondarySize.SM : ButtonSecondarySize.LG}
              onClick={() => window.location.reload()}
            >
              Atualizar página
            </S.ButtonSecondary>
          ),
        };
    }
  };

  const { headingText, paragraphText, errorText, feedbackText, buttonAction } =
    getErrorContent();

  return (
    <S.Container>
      <S.Hero>
        <FsTextContent
          type={TextContentType.Error}
          heading={headingText}
          paragraph={paragraphText}
          errorText={errorText}
        />
        {buttonAction && buttonAction()}
      </S.Hero>
      <FeedbackPageLettering text={feedbackText} />
      <S.TopicsWrapper>
        <FsTopicIcon
          icon="HomeLine"
          paragraph="Se estiver perdido, retorne à página inicial para navegar por todas as seções do site e encontrar o que procura."
          headingProps={{
            children: 'Retorne à página inicial',
            tag: HeadingTag.H2,
          }}
        />
        <FsTopicIcon
          icon="RefreshLine"
          paragraph="Se a informação não estiver aparecendo corretamente, tente recarregar a página para garantir que você esteja visualizando a versão mais recente."
          headingProps={{
            children: 'Atualize a página',
            tag: HeadingTag.H2,
          }}
        />
        <FsTopicIcon
          icon="WhatsappLine"
          paragraph="Entre em contato com nosso suporte. Estamos aqui para ajudar e solucionar qualquer problema que você possa enfrentar."
          headingProps={{
            children: 'Entre em contato',
            tag: HeadingTag.H2,
          }}
        />
      </S.TopicsWrapper>
    </S.Container>
  );
}
