'use client';

/* eslint-disable import/order */
import { Banner } from '@mf/promotions/components/Banner';
import {
  BannerHeroSize,
  ButtonSecondarySize,
  FsButtonSecondary,
  FsTopSection,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import { Carousel } from '@mf/common/components/index';
import Link from 'next/link';
import { useLayout } from '@mf/common/components/Media/Layout';
import strapiProductCategory from 'repositories/aecomStrapiCms/productCategory';
import strapiProduct from 'repositories/aecomStrapiCms/product';
import { ProductsList } from '@/components/main/ProductsList';
import { CategoryImage } from '@/components/main/CategoryImages';
import { ContextualFaq } from '@/components/main/ContextualFaq';
import { CategoryTopSection } from '@/components/main/CategoryTopSection/CategoryTopSection';
import { DropdownOrder } from '../Products/Products.types';
import { useProductsHooks } from '../Products';
import * as S from './Category.styles';

interface CategoryScreenParams {
  slug: string;
}

export function CategoryScreen({ slug }: CategoryScreenParams) {
  const auth = {
    baseUrl: process.env.BFF_API_BASE_URL,
  };

  const pageSize = 8;

  const { useProductCategory } = strapiProductCategory(auth);
  const { useProductsListing } = strapiProduct(auth);
  const { setShowSearchButton } = useLayout();
  setShowSearchButton(true);

  const { data: category } = useProductCategory(slug);
  const {
    data: bestSellings,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
    hasNextPage,
  } = useProductsListing({
    params: {
      pagination: { page: 0, pageSize: pageSize },
      filters: {
        classification_options: {
          slug: {
            $contains: slug,
          },
        },
      },
      sort: [{ ranking: 'asc' }, { name: 'asc' }],
      populate: ['banner_image', 'small_image', 'small_image_hover'],
    },
  });
  const { getSortToQuery } = useProductsHooks();

  const relatedCategory =
    category?.attributes?.relatedCategory?.data?.attributes;
  const totalBestSellings = bestSellings?.pages[0]?.meta.pagination.total || 0;
  const relatedCategorySlug: string =
    relatedCategory?.classificationOption?.data?.attributes?.slug ?? '';

  const dynamicFaq = () => {
    return {
      heading: `Sobre ${category?.attributes?.name?.toLowerCase()}`,
      faqs: category?.attributes?.faq?.length ? category?.attributes?.faq : [],
    };
  };

  const { data: relatedProducts } = useProductsListing({
    params: {
      pagination: { page: 0, pageSize },
      filters: {
        classification_options: {
          slug: {
            $contains: relatedCategorySlug,
          },
        },
      },
      sort: [getSortToQuery(DropdownOrder.BestSellers)],
      populate: ['banner_image', 'small_image', 'small_image_hover'],
    },
  });

  return (
    <>
      <S.HeroSection>
        <CategoryTopSection
          category={
            category?.attributes?.classificationOption?.data?.attributes
              ?.name || ''
          }
          description={category?.attributes?.description || ''}
          slug={slug}
        />
        <S.HeroImages>
          <CategoryImage
            image={category?.attributes?.featuredImage?.data || null}
          />
        </S.HeroImages>
      </S.HeroSection>
      <S.GridProductWrapper>
        <FsTopSection
          description={`${totalBestSellings} Produto(s)`}
          heading="Mais vendidos"
          headingProps={{ tag: HeadingTag.H2 }}
        />
        <S.GridProductsCards>
          {/* <S.ProductsRow>
            { bestSellings?.pages[0].map((product) => (
                <Link href={`/${product.attributes.slug}`} key={product.id}>
                  <CardProduct product={product} key={product.id} />
                </Link>
              ))}
          </S.ProductsRow> */}
          <ProductsList
            heading="Mais vendidos"
            isFetching={isFetchingNextPage || isFetching}
            listing={bestSellings?.pages || []}
          />
          {hasNextPage && !!bestSellings?.pages[0]?.meta.pagination.total && (
            <S.Action>
              <FsButtonSecondary
                size={ButtonSecondarySize.LG}
                loading={isFetchingNextPage}
                onClick={() => fetchNextPage()}
              >
                Carregar mais
              </FsButtonSecondary>
            </S.Action>
          )}
        </S.GridProductsCards>
      </S.GridProductWrapper>
      <S.CarouselWrapper>
        <Carousel
          heading="Produtos Relacionados"
          cards={relatedProducts?.pages[0]?.data || []}
        />
      </S.CarouselWrapper>
      {category?.attributes?.relatedCategory?.data?.id && (
        <Link
          href={`/${relatedCategory?.classificationOption?.data?.attributes?.slug}/`}
        >
          <S.BannerSection>
            <Banner
              heading={
                relatedCategory?.classificationOption?.data?.attributes?.name ||
                ''
              }
              description="Categoria"
              actionUrl={`/${relatedCategory?.classificationOption?.data?.attributes?.slug}`}
              actionLabel="Ver Produtos"
              bannerImageAlt={`Banner de categoria de ${relatedCategory?.classificationOption?.data?.attributes?.slug}`}
              bannerImage={
                category?.attributes?.bannerImage?.data.attributes.url || ''
              }
              size={BannerHeroSize.LG}
              id="banner-related-category"
            />
          </S.BannerSection>
        </Link>
      )}
      <ContextualFaq
        dynamicFaq={dynamicFaq()}
        content={category?.attributes?.content || []}
      />
    </>
  );
}
