import {
  FsAccordionGroup,
  FsParagraph,
  FsTopSection,
  HeadingTag,
  TopSectionType,
} from '@printi/ds-offset-react-core';
import { BlocksContent } from '@strapi/blocks-react-renderer';
import { ContentRenderer } from '../ContentRenderer';
import * as S from './ContextualFaq.styles';

type dynamicFaq = {
  heading: string;
  faqs: Array<{ id: number; question: string; answer: string }>;
};

interface ContextualFaqProps {
  dynamicFaq?: dynamicFaq;
  content?: BlocksContent[];
}

export const ContextualFaq = ({ dynamicFaq, content }: ContextualFaqProps) => {
  return (
    <S.Section>
      <S.Container>
        <S.Bottom>
          {content !== null && !!content?.length && (
            <>
              <FsTopSection
                heading={dynamicFaq?.heading}
                showDescription={false}
                type={TopSectionType.ButtonMini}
                headingProps={{ tag: HeadingTag.H2 }}
              />
              {content !== null && <ContentRenderer content={content} />}
            </>
          )}
          {dynamicFaq?.faqs?.length ? (
            <>
              <FsTopSection
                heading={'Dúvidas Frequentes'}
                showDescription={false}
                type={TopSectionType.ButtonMini}
                headingProps={{ tag: HeadingTag.H2 }}
                style={{
                  marginTop: 'var(--spacing-3x)',
                }}
              />
              <FsAccordionGroup id="accordion-group" expandedIndexes={[]}>
                {dynamicFaq.faqs.map((faq, index) => (
                  <S.Accordion
                    id={`accordion-${index}`}
                    index={index}
                    label={faq.question}
                    key={faq.id}
                  >
                    <FsParagraph>{faq.answer}</FsParagraph>
                  </S.Accordion>
                ))}
              </FsAccordionGroup>
            </>
          ) : (
            <>
              <FsTopSection
                heading="Dúvidas Frequentes"
                headingProps={{ tag: HeadingTag.H2 }}
                showDescription={false}
                type={TopSectionType.ButtonMini}
                style={{
                  marginTop: 'var(--spacing-3x)',
                }}
              />
              <FsAccordionGroup id="accordion-group" expandedIndexes={[]}>
                <S.Accordion
                  id="accordion-1"
                  index={0}
                  label="Como compro um produto personalizado na Printi?"
                >
                  <>
                    <FsParagraph>
                      Seu primeiro passo é escolher seus produtos e definir suas
                      preferências como formato e material. Em seguida,
                      adicione-os ao carrinho e finalize a compra, selecionando
                      a forma de pagamento e entrega que desejar.
                    </FsParagraph>
                    <br></br>
                    <br></br>
                    <FsParagraph>
                      Após concluir a compra, você poderá personalizar seus
                      produtos. Na Printi, oferecemos várias opções de
                      personalização: envie sua própria arte, contrate um
                      designer Printi, ou experimente a Printi AI, a
                      inteligência artificial da Printi.
                    </FsParagraph>
                  </>
                </S.Accordion>
                <S.Accordion
                  id="accordion-2"
                  index={1}
                  label="Como a Printi garante a qualidade do meu pedido?"
                >
                  <>
                    <FsParagraph>
                      Aqui na Printi nosso compromisso é com a qualidade.
                    </FsParagraph>
                    <br></br>
                    <br></br>
                    <FsParagraph>
                      Temos processos e muita tecnologia para garantir que seu
                      pedido seja atendido com precisão. Mas, se ainda assim
                      acontecer um imprevisto, garantimos a reimpressão e
                      reenvio gratuito.
                    </FsParagraph>
                    <br></br>
                    <br></br>
                    <FsParagraph>
                      Cada processo da Printi é pensado para garantir qualidade
                      e a sua tranquilidade.
                    </FsParagraph>
                  </>
                </S.Accordion>
                <S.Accordion
                  id="accordion-3"
                  index={2}
                  label="Consigo pedir apenas uma unidade?"
                >
                  <>
                    <FsParagraph>
                      A quantidade mínima pode variar por produto, mas
                      oferecemos tiragens pequenas, médias e grandes, de acordo
                      com a sua necessidade.
                    </FsParagraph>
                    <br></br>
                    <br></br>
                    <FsParagraph>
                      Há produtos como camisetas, brindes e fotolivros que é
                      possível encomendar apenas uma unidade, sendo uma ótima
                      opção para dar personalidade à um presente ou ambiente,
                      por exemplo.
                    </FsParagraph>
                    <br></br>
                    <br></br>
                    <FsParagraph>
                      Encontre o seu produto ideal e personalize agora mesmo.
                    </FsParagraph>
                  </>
                </S.Accordion>
                <S.Accordion
                  id="accordion-4"
                  index={3}
                  label="A Printi confere meus arquivos antes de enviar para impressão?"
                >
                  <>
                    <FsParagraph>
                      Na Printi, assim que recebemos seu arquivo com a arte, ele
                      é enviado para {'"'}Pré-impressão{'"'}, onde analisamos os
                      arquivos para garantir que estão em conformidade com
                      nossos gabaritos e diretrizes, garantindo uma impressão de
                      alta qualidade.
                    </FsParagraph>
                    <br></br>
                    <br></br>
                    <FsParagraph>
                      Se houver algo fora das especificações, você receberá um
                      e-mail com as orientações para ajuste.
                    </FsParagraph>
                  </>
                </S.Accordion>
                <S.Accordion
                  id="accordion-5"
                  index={4}
                  label="Como funciona a entrega do meu pedido? E se eu pedir mais de um tipo de produto?"
                >
                  <>
                    <FsParagraph>
                      Para facilitar sua gestão e reduzir os custos com frete,
                      pedidos com mais de um item sendo produzido serão enviados
                      em uma única entrega. O prazo de entrega será determinado
                      pelo item com o maior tempo de produção, garantindo que
                      todos os produtos sejam entregues juntos.
                    </FsParagraph>
                    <br></br>
                    <br></br>
                    <FsParagraph>
                      Caso tenha a necessidade ou deseje receber algum produto
                      em um prazo diferente dos demais, recomendamos que efetue
                      pedidos diferentes.
                    </FsParagraph>
                    <br></br>
                    <br></br>
                    <FsParagraph>
                      Lembre-se: o tempo de produção de cada item pode variar
                      conforme as especificações escolhidas, como quantidade,
                      formato e acabamento.
                    </FsParagraph>
                  </>
                </S.Accordion>
                <S.Accordion
                  id="accordion-6"
                  index={5}
                  label="Estou precisando de ajuda, com quem posso conversar?"
                >
                  <>
                    <FsParagraph>
                      Se você estiver precisando de um atendimento personalizado
                      para esclarecer dúvidas sobre os produtos e sobre a
                      Printi, você pode entrar em contato com a nossa Central de
                      Atendimento via WhatsApp{' '}
                      <a
                        href="https://api.whatsapp.com/send?phone=551141183816"
                        target="_blank"
                        style={{
                          textDecoration: 'underline',
                        }}
                      >
                        (11) 4118-3816.
                      </a>
                    </FsParagraph>
                  </>
                </S.Accordion>
              </FsAccordionGroup>
            </>
          )}
        </S.Bottom>
      </S.Container>
    </S.Section>
  );
};
