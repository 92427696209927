import styled from 'styled-components';
import media from '@mf/common/utils/styles/media';
import { FsListContent } from '@printi/ds-offset-react-core';

export const Top = styled.div`
  display: flex;
  padding-top: var(--spacing-2x);
  padding-bottom: var(--spacing-2x);
  align-items: center;
  align-self: stretch;
  border-bottom: 1.6px solid var(--elements-border, #f5f5f5);

  h2 {
    font: var(--_label-lg-2-font);
  }
`;

export const Container = styled.div`
  margin-top: var(--spacing-2x);
  display: flex;
  padding: var(--spacing-1_5x) var(--spacing-4x);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-2x);
  align-self: stretch;
  border-radius: var(--border-radius-md);
  border: 1.6px solid var(--elements-border, #f5f5f5);

  > [type='image'] {
    width: -webkit-fill-available;
  }

  p {
    width: max-content;
  }

  ${media.lessThan('mobile')} {
    padding: var(--spacing-1_5x) var(--spacing-2x);
  }

  > div {
    &[type='square'] {
      height: var(--spacing-5x) !important;
      width: 100% !important;
    }
  }
`;

export const ListContent = styled(FsListContent)`
  div[orientation='Horizontal'] {
    flex: 1 0 0;

    span {
      margin-bottom: 0 !important;
    }

    :first-child {
      gap: 0;
    }
  }
`;
