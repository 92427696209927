import { media } from '@mf/common/utils/styles/index';
import styled from 'styled-components';
import { FsAccordion } from '@printi/ds-offset-react-core';

export const Section = styled.section`
  display: flex;
  padding: var(--spacing-10x) 0px var(--spacing-20x) 0px;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  align-self: stretch;

  ${media.lessThan('mobile')} {
    padding: var(--spacing-8x) var(--spacing-2x);
  }
`;

export const Container = styled.div`
  width: -webkit-fill-available;
  display: flex;
  padding: var(--spacing-15x);
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-5x);
  border: var(--default-border-width) solid var(--elements-border-color);
  border-radius: var(--default-border-radius);

  ${media.lessThan('mobile')} {
    width: auto;
    padding: 0;
    justify-content: center;
    align-items: flex-start;
    gap: var(--spacing-3x);
    align-self: stretch;
    border: none;
  }
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-none);
  align-self: stretch;

  ${media.lessThan('mobile')} {
    flex: 1 0 0;
  }
`;

export const Accordion = styled(FsAccordion)`
  h3 {
    color: var(--text-label-color);
  }
`;
