/* eslint-disable import/order */

import {
  ButtonSize,
  CurrencyPosition,
  CurrencySize,
  FlagType,
  FsButton,
  FsCurrency,
  FsFlag,
  FsInputText,
  FsLoading,
  HeadingTag,
  IconPosition,
  ModalType,
} from '@printi/ds-offset-react-core';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import React from 'react';
import { useProductStore } from '@mf/common/store/product/product.store';
import { formatCurrency } from '@brazilian-utils/brazilian-utils';
import { useMutation } from '@tanstack/react-query';
import { TCombinationQuantity } from '@mf/common/store/product/product.types';
import { useDrawerProductConfigHooks } from '../DrawerProductConfig/DrawerProductConfig.hooks';
import {
  TCustomQuantity,
  TDefaultCombinationsQuantities,
} from '@/services/product/types';
import productService from '@/services/product';
import { useModalProductQuantityHooks } from './ModalProductQuantity.hooks';
import { Modal, ModalContent } from './ModalProductQuantity.styles';
import productRules from '@mf/common/repositories/cdn/deny-rules';
import { TDeny } from '@mf/common/repositories/cdn/deny-rules/types';

interface ModalProductQuantityProps {
  isOpen: boolean;
  setOpenQtt: Dispatch<SetStateAction<boolean>>;
  custom_quantity?: TCustomQuantity;
  final_product_id?: string;
}

type TError = {
  text: string;
  error: boolean;
};

export const ModalProductQuantity = ({
  isOpen,
  setOpenQtt,
  custom_quantity,
  final_product_id,
}: ModalProductQuantityProps) => {
  const [selectedQtt, setSelectedQtt] = useState<number>(1);
  const [error, setError] = useState<TError>({
    error: false,
    text: '',
  });
  const inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const [min, setMin] = useState<number>(0);
  const [max, setMax] = useState<number>(0);

  const [quantityState, setQuantityState] = useState<{
    min?: number;
    max?: number;
    equal: number[];
  }>({
    equal: [],
  });

  const {
    quantity,
    updateQuantity,
    printModelCombination,
    setQuantitiesOptions,
  } = useProductStore();

  const [quantityObj, setQuantityObj] =
    useState<TDefaultCombinationsQuantities>({
      quantity: quantity.quantity,
      price: quantity.price,
      unit_price: quantity.unit_price,
      token: quantity.token,
    });
  const [quantities, setQuantities] = useState<TCombinationQuantity[]>([]);

  const {
    validCustomOrDefaultOption,
    defaultValidOption,
    validCustomOption,
    isNumericArray,
  } = useDrawerProductConfigHooks({ productId: final_product_id || '' });

  const { getProductQuantity } = productService({
    baseUrl: process.env.BFF_API_BASE_URL,
  });

  const { useProductDenyRules } = productRules({
    baseUrl: '/api',
  });

  const { data: denyRules } = useProductDenyRules({
    productId: final_product_id,
  });

  const { useDebounce } = useModalProductQuantityHooks();
  const debounceQtt = useDebounce<number>(selectedQtt, 700);

  const handleConfirm = () => {
    setQuantitiesOptions(quantities);
    updateQuantity(quantityObj);
    setOpenQtt(false);
    setSelectedQtt(custom_quantity?.min || 1);
  };

  const handleClose = () => {
    setQuantityState({
      equal: [],
    });
    setSelectedQtt(custom_quantity?.min || 1);
    setMin(custom_quantity?.min || 1);
    setMax(custom_quantity?.max || 0);
    setOpenQtt(false);
  };

  const handleQuantity = (e: React.FormEvent<HTMLDivElement>) => {
    e.preventDefault();
    const value = (e.target as HTMLInputElement).value;
    if (value) {
      setSelectedQtt(parseInt(value));
    } else {
      setSelectedQtt(0);
    }
  };

  const findQuantity = (value: number, quantities: TCombinationQuantity[]) => {
    const quantity = quantities.find((qnt) => qnt.quantity === value);

    return quantity || null;
  };

  const isMultipleOf = (num: number, multipleOf: number) => {
    if (multipleOf === 0) {
      return false;
    }
    return num % multipleOf === 0;
  };

  const verifyDenyQuantity = (deny: TDeny) => {
    if (isNumericArray(deny?.product_quantities_custom)) {
      const newEqualValues = Array.isArray(deny.product_quantities_custom)
        ? deny.product_quantities_custom
        : [deny.product_quantities_custom];

      setQuantityState((prevState) => ({
        ...prevState,
        equal: [...prevState.equal, ...newEqualValues],
      }));
    } else if (typeof deny?.product_quantities_custom === 'number') {
      const value = deny?.product_quantities_custom as number;

      if (deny?.product_quantities_custom_type === '>') {
        setQuantityState((prevState) => ({
          ...prevState,
          max: prevState.max ? Math.max(prevState.max, value || 0) : value,
        }));
      } else if (deny?.product_quantities_custom_type === '<') {
        setQuantityState((prevState) => ({
          ...prevState,
          min: prevState.min ? Math.max(prevState.min, value || 0) : value,
        }));
      }
    }
  };

  const validQuantity = () => {
    const filteredRules = denyRules?.filter(
      (rule) => rule?.deny?.product_quantities_custom !== undefined,
    );

    printModelCombination?.parts?.forEach((productPartCombination) => {
      filteredRules?.forEach((rule) => {
        const selectOptions = rule.select?.product_options;
        const selectCustomOptions = rule.select?.product_options_custom;
        const denyOption = rule?.deny;
        let check = false;

        let matchesAllSelects = false;
        if (selectOptions) {
          matchesAllSelects = Object.entries(selectOptions).every(
            ([attribute, values]) => {
              const optionValue =
                productPartCombination.options[
                  attribute as keyof typeof productPartCombination.options
                ];

              const validPage = validCustomOrDefaultOption({
                optionValue,
                selectCustomOptions,
                attribute,
                validAttribute: 'page',
              });

              const validFormat = validCustomOrDefaultOption({
                optionValue,
                selectCustomOptions,
                attribute,
                validAttribute: 'format',
              });

              const defaultOption = defaultValidOption({
                optionValue,
                values,
              });

              if (typeof validPage !== 'undefined') {
                return defaultOption || validPage;
              }

              if (typeof validFormat !== 'undefined') {
                return defaultOption || validFormat;
              }

              return defaultOption;
            },
          );
        }

        let matchesAllCustomSelects = false;
        if (selectCustomOptions) {
          matchesAllCustomSelects = Object.entries(selectCustomOptions).every(
            ([attribute, customOption]) => {
              // Somente 'page' e 'format' possuem opções personalizadas
              if (['page', 'format'].includes(attribute)) {
                if (selectOptions) {
                  if (selectOptions['format']) {
                    return true;
                  }

                  if (selectOptions['page']) {
                    return true;
                  }
                }

                const optionValue =
                  productPartCombination.options[
                    attribute as keyof typeof productPartCombination.options
                  ];

                if (optionValue && String(optionValue).includes('custom')) {
                  return validCustomOption({ customOption, attribute });
                }
              }
              return false;
            },
          );
        }

        if (selectOptions && selectCustomOptions) {
          check = matchesAllSelects && matchesAllCustomSelects;
        } else {
          check = matchesAllSelects || matchesAllCustomSelects;
        }

        if (denyOption && check) {
          verifyDenyQuantity(denyOption);
        }
      });
    });
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    validQuantity();
    // eslint-disable-next-line
  }, [printModelCombination, isOpen]);

  useEffect(() => {
    if (quantityState.max) {
      setMax(quantityState.max);
    }

    if (quantityState.min) {
      setMin(quantityState.min);
      setSelectedQtt(quantityState.min);
    }
  }, [quantityState]);

  useEffect(() => {
    setSelectedQtt(custom_quantity?.min || 1);
  }, [custom_quantity]);

  useEffect(() => {
    if (!custom_quantity) {
      return;
    }

    if (selectedQtt < min || selectedQtt > max || !selectedQtt) {
      setError({
        text: '',
        error: true,
      });
    } else if (custom_quantity.multiple) {
      if (!isMultipleOf(selectedQtt, custom_quantity.multiple)) {
        setError({
          text: `A quantia deve ser múltiplo de ${custom_quantity.multiple}`,
          error: true,
        });
      } else {
        setError({
          text: ``,
          error: false,
        });
      }
    } else {
      setError({
        text: ``,
        error: false,
      });
    }
  }, [selectedQtt, custom_quantity, min, max]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    setMax(custom_quantity?.max || 0);
    setMin(custom_quantity?.min || 0);
  }, [isOpen, custom_quantity]);

  const { mutate: mutateProductQuantity, isLoading } = useMutation(
    getProductQuantity,
    {
      onSuccess: (data) => {
        if (!data.length) {
          return;
        }

        setQuantities(data);

        const quantity = findQuantity(selectedQtt, data);

        if (quantity) {
          setQuantityObj(quantity);
        }
      },
    },
  );

  useEffect(() => {
    if (
      !debounceQtt ||
      !printModelCombination ||
      !final_product_id ||
      !isOpen ||
      error.error
    ) {
      return;
    }

    const product_parts = printModelCombination?.parts || [];
    const quantities = ['default', debounceQtt];

    const payload = {
      final_product_id: final_product_id,
      product_parts,
      quantities,
    };

    mutateProductQuantity(payload);
  }, [
    debounceQtt,
    printModelCombination,
    final_product_id,
    mutateProductQuantity,
    isOpen,
    error,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      type={ModalType.Slot}
      heading="Editar quantidade"
      headingProps={{
        tag: HeadingTag.SPAN,
      }}
      onClose={handleClose}
      fixedBarActionSlot={
        <FsButton
          onClick={handleConfirm}
          disabled={error.error}
          loading={isLoading}
          size={ButtonSize.LG}
        >
          Confirmar
        </FsButton>
      }
      fixedBarContentSlot={
        <>
          {isLoading ? (
            <FsLoading />
          ) : (
            <FsCurrency
              description="Total"
              descriptionPosition={CurrencyPosition.Top}
              price={
                error.error
                  ? 'R$ --'
                  : `R$ ${formatCurrency(quantityObj.price)}`
              }
              size={CurrencySize.LG}
            />
          )}
        </>
      }
    >
      <ModalContent>
        <FsInputText
          ref={inputRef}
          id="input-quantity"
          label="Insira uma quantidade"
          placeholder={(custom_quantity?.min || 1).toString()}
          supportText={
            error.text ||
            `Escolha uma quantia entre ${min} e ${formatCurrency(max || 0, { precision: 0 })}`
          }
          showButton={false}
          type="number"
          value={selectedQtt.toString()}
          error={error.error}
          inputProps={{
            type: 'number',
          }}
          onChange={handleQuantity}
        />

        <FsFlag
          heading="Quantidades maiores"
          type={FlagType.Informative}
          iconPosition={IconPosition.Left}
        >
          Consulte nossa equipe comercial através do {''}
          <a
            href="https://wa.me/551141183816"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline' }}
          >
            Whatsapp
          </a>
          .
        </FsFlag>
      </ModalContent>
    </Modal>
  );
};
