import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  max-height: var(--size-10x);
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-end;
  gap: var(--spacing-1_5x);
  align-self: stretch;
  flex: 1 0 0;

  img {
    display: flex;
    width: var(--size-10x);
    height: var(--size-10x);
    align-items: flex-start;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-half);
  flex: 1 0 0;
  align-self: stretch;
`;

export const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
