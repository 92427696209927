import {
  FsAccordionSelect,
  FsAccordionSelectItem,
  FsCardOutline,
  FsDescription,
  FsRadioButton,
} from '@printi/ds-offset-react-core';
import styled from 'styled-components';

export const AccordionGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4x);
`;

export const AccordionSelect = styled(FsAccordionSelect)`
  cursor: pointer;

  button {
    > div {
      > div {
        &[type='informative'] {
          background-color: #d9e8ff;
          span {
            color: #073c8c;
          }
        }
      }
    }
  }
`;

export const RadioButton = styled(FsRadioButton)`
  pointer-events: none;
`;

export const CardOutline = styled(FsCardOutline)`
  align-items: center;
  display: flex;
  gap: var(--spacing-2x);
  margin: 0 0 var(--spacing-3x);
  min-height: auto;
`;

export const CardOutlineContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-half);
  width: 100%;
`;

export const ImageShape = styled.div`
  align-items: center;
  background-color: var(--elements-background-color);
  border-radius: var(--default-border-radius);
  display: flex;
  height: 100px;
  justify-content: center;
  width: 100px;
`;

export const AccordionSelectItem = styled(FsAccordionSelectItem)`
  display: grid;
  padding: var(--spacing-3x);
`;

export const AccordionSelectItemSlot = styled(FsAccordionSelectItem)`
  padding: var(--spacing-none);

  > div {
    flex-direction: column;
    gap: var(--spacing-none);
  }
`;

export const AccordionSelectItemOptions = styled.div`
  align-items: center;
  box-sizing: border-box;
  border-top: var(--default-border-width) solid var(--elements-border-color);
  display: flex;
  gap: var(--spacing-1x);
  padding: var(--spacing-3x) var(--spacing-4x);
  width: 100%;

  &:first-child {
    border-top: none;
  }
`;

export const AccordionSelectItemDescription = styled(FsDescription)`
  min-width: 160px;
`;
