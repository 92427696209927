import { media } from '@mf/common/utils/styles/index';
import { FsListContent } from '@printi/ds-offset-react-core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: var(--spacing-1_5x) var(--spacing-4x);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-2x);
  align-self: stretch;
  border-radius: var(--border-radius-md);
  border: 1.6px solid var(--elements-border, #f5f5f5);

  ${media.lessThan('mobile')} {
    padding: var(--spacing-1_5x) var(--spacing-2x);
  }

  > div {
    &[type='square'] {
      height: var(--spacing-5x) !important;
      width: 100% !important;
    }
  }
`;

export const ListContentGroup = styled.div`
  display: flex;
  padding: var(--spacing-none);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--shape-spacing-half, 4px);
  align-self: stretch;
`;

export const ListContent = styled(FsListContent)`
  width: -webkit-fill-available;

  div[orientation='Horizontal'] {
    flex: 1 0 0;

    span {
      margin-bottom: 0 !important;
    }

    :first-child {
      gap: 0;
    }
  }
`;
