'use client';

import { DesktopView } from '@mf/common/components/index';
import {
  FsBreadcrumbItem,
  FsDescription,
  FsHeading,
  FsParagraph,
  HeadingSize,
} from '@printi/ds-offset-react-core';
import { useLayout } from '@mf/common/components/Media/Layout';
import * as S from './CategoryTopSection.styles';

interface CategoryTopSectionProps {
  category: string;
  description: string;
  slug: string;
}

export const CategoryTopSection = ({
  category,
  description,
  slug,
}: CategoryTopSectionProps) => {
  const { isMobile } = useLayout();

  return (
    <>
      <S.TopSection>
        <DesktopView>
          <S.BreadCrumb>
            <FsBreadcrumbItem href="/">Início</FsBreadcrumbItem>
            <FsBreadcrumbItem href={`/${slug}/`}>{category}</FsBreadcrumbItem>
          </S.BreadCrumb>
        </DesktopView>
        <S.Content>
          <S.Title>
            <FsDescription>Categoria</FsDescription>
            <FsHeading size={isMobile ? HeadingSize.MD : HeadingSize.LG}>
              {category}
            </FsHeading>
          </S.Title>
          <S.Description>
            <FsParagraph>{description}</FsParagraph>
          </S.Description>
        </S.Content>
      </S.TopSection>
    </>
  );
};
