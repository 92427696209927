'use client';

import React from 'react';
import {
  ButtonIconSize,
  FsButtonIcon,
  FsSkeleton,
  FsTooltip,
  FsTopSection,
  HeadingTag,
  ListContentType,
  ListContentVariant,
  TooltipPosition,
  TopSectionType,
} from '@printi/ds-offset-react-core';
import bffTemplate from '@mf/common/repositories/bff/templates/index';
import { useProductStore } from '@mf/common/store/product/product.store';
import { useEffect, useState } from 'react';
import { TTemplate } from '@mf/common/repositories/bff/templates/types';
import Image from 'next/image';
import * as S from './TemplateDownload.styles';

type FileWithOrientation = {
  orientation: string;
  type: string;
  url: string;
  parts?: { id: string; url: string }[];
};

export const TemplateDownload = ({
  finalProductId,
}: {
  finalProductId?: string;
}) => {
  const [isCustom, setIsCustom] = useState<boolean>(false);
  const [orderData, setOrderData] = useState<FileWithOrientation[]>([]);

  const apiAuth = {
    baseUrl: process.env.BFF_API_BASE_URL,
  };

  const { useTemplateDownload } = bffTemplate(apiAuth);
  const { printModelCombination } = useProductStore();

  const {
    data: templatesResponse,
    mutate: getTemplates,
    isLoading,
  } = useTemplateDownload();

  useEffect(() => {
    if (!printModelCombination || !finalProductId) {
      return;
    }

    let custom = false;
    printModelCombination.parts.forEach((part) => {
      if (part.options) {
        Object.entries(part.options).forEach(([, value]) => {
          if (value) {
            if (typeof value === 'string' && value.includes('custom=')) {
              custom = true;
            }
          }
        });
      }
    });

    if (custom) {
      setIsCustom(true);
      return;
    }

    setIsCustom(false);

    getTemplates({
      finalProductId,
      body: {
        product_parts: printModelCombination.parts,
      },
    });
  }, [printModelCombination, finalProductId, getTemplates]);

  const getOrientation = (orientation: string) => {
    const orientations = {
      unique: 'Única',
      horizontal: 'Horizontal',
      vertical: 'Vertical',
    };

    return orientations[orientation as keyof typeof orientations];
  };

  const getProps = (type: string) => {
    const types = {
      illustrator: {
        name: 'Illustrator',
        tag: '.ai',
        img: 'https://d1br4h274rc9sc.cloudfront.net/content/ai_d2e3803c4a.svg',
      },
      photoshop: {
        name: 'Photoshop',
        tag: '.psd',
        img: 'https://d1br4h274rc9sc.cloudfront.net/content/psd_09083d03f4.svg',
      },
      indesign: {
        name: 'Indesign',
        tag: '.indd',
        img: 'https://d1br4h274rc9sc.cloudfront.net/content/indd_e2eeecac60.svg',
      },
      pdf: {
        name: 'Acrobat Reader',
        tag: '.pdf',
        img: 'https://d1br4h274rc9sc.cloudfront.net/content/pdf_1403bd3b33.svg',
      },
      coreldraw: {
        name: 'Corel Draw',
        tag: '.cdr',
        img: 'https://d1br4h274rc9sc.cloudfront.net/content/cdr_76c0cfa08b.svg',
      },
      canva: {
        name: 'Canva',
        tag: '',
        img: 'https://d1br4h274rc9sc.cloudfront.net/content/Canva_a2873a4aae_803b7c4045.svg',
      },
    };

    return types[type as keyof typeof types];
  };

  const sortFilesByType = (items: TTemplate[]) => {
    const allFiles: FileWithOrientation[] = items.flatMap((item) =>
      item.files.map((file) => ({
        ...file,
        orientation: item.orientation,
      })),
    );

    return allFiles.sort((a, b) => a.type.localeCompare(b.type));
  };

  useEffect(() => {
    if (
      !Array.isArray(templatesResponse?.data) ||
      !templatesResponse?.data?.length
    ) {
      setOrderData([]);
      return;
    }

    setOrderData(sortFilesByType(templatesResponse.data));
  }, [templatesResponse]);

  const noDataTemplate = (!orderData.length && !isLoading) || isCustom;

  return (
    <S.Container>
      <S.Top>
        <FsTopSection
          style={{
            paddingBottom: '0px',
          }}
          type={TopSectionType.Info}
          showDescription={false}
          heading="Gabaritos"
          headingProps={{
            tag: HeadingTag.H2,
          }}
          actionSlot={
            <FsTooltip
              label="Baixe os arquivos editáveis com as especificações de impressão"
              position={TooltipPosition.Left}
            >
              <FsButtonIcon icon="InformationLine" size={ButtonIconSize.LG} />
            </FsTooltip>
          }
        />
      </S.Top>
      {isLoading && (
        <>
          <FsSkeleton />
          <FsSkeleton />
          <FsSkeleton />
        </>
      )}
      {noDataTemplate && (
        <S.ListContent
          style={{
            border: 'none',
            padding: '0px',
            paddingTop: 'var(--spacing-2x)',
            paddingBottom: 'var(--spacing-2x)',
          }}
          type={ListContentType.IconShape}
          variant={ListContentVariant.Outline}
          lastItem={true}
          icon="FileLine"
          showHeading={false}
          showButton={false}
          showLabelLeft={true}
          labelLeft={`Infelizmente não temos gabaritos para formatos personalizados.`}
          showTag={false}
          labelRight=""
          description=""
        />
      )}

      {!isCustom &&
        !isLoading &&
        orderData?.map((item, key) => {
          const props = getProps(item.type);

          if (item.type === 'canva') {
            return item?.parts?.map((canva) => (
              <S.ListContent
                width={'95%'}
                key={props?.tag}
                showButton
                type={ListContentType.Image}
                variant={ListContentVariant.Line}
                heading={props?.name}
                buttonLabel="Abrir"
                onButtonClick={() => {
                  window.location.href = canva.url;
                }}
                showLabelLeft={false}
                tagText={props?.tag}
                img={
                  <Image src={props?.img || ''} alt={props?.name || ''} fill />
                }
                labelRight={''}
                description={''}
                lastItem={orderData.length - 1 === key}
              />
            ));
          } else {
            return (
              <S.ListContent
                width={'95%'}
                key={props?.tag}
                showButton
                type={ListContentType.Image}
                variant={ListContentVariant.Line}
                heading={props?.name}
                buttonLabel="Baixar"
                onButtonClick={() => {
                  window.location.href = item.url;
                }}
                showLabelLeft={false}
                tagText={props?.tag}
                img={
                  <Image src={props?.img || ''} alt={props?.name || ''} fill />
                }
                labelRight={''}
                description={
                  ((Array.isArray(templatesResponse?.data) &&
                    templatesResponse?.data?.length) ||
                    1) > 1
                    ? getOrientation(item.orientation)
                    : ''
                }
                lastItem={orderData.length - 1 === key}
              />
            );
          }
        })}
    </S.Container>
  );
};
