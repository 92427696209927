import {
  FsDescription,
  FsHeading,
  FsTag,
  HeadingSize,
  HeadingTag,
  TagType,
} from '@printi/ds-offset-react-core';
import Image from 'next/image';
import * as S from './ProductPreview.styles';

interface ProductPreviewProps {
  image: string;
  heading: string;
  description: string;
  tag?: string;
  tagType?: TagType;
}

export const ProductPreview = ({
  image,
  heading,
  description,
  tag,
  tagType,
}: ProductPreviewProps) => {
  return (
    <S.Container>
      <S.Content>
        <Image src={image} alt={heading} width={80} height={80} />
        <S.Description>
          <FsHeading size={HeadingSize.XS} tag={HeadingTag.SPAN}>
            {heading}
          </FsHeading>
          <FsDescription>{description}</FsDescription>
        </S.Description>
        {tag && (
          <S.TagWrapper>
            <FsTag label={tag} type={tagType} />
          </S.TagWrapper>
        )}
      </S.Content>
    </S.Container>
  );
};
