import productRules from '@mf/common/repositories/cdn/deny-rules/index';
import {
  TCustomOption,
  TProductCustomOptions,
} from '@mf/common/repositories/cdn/deny-rules/types';
import { useProductStore } from '@mf/common/store/product/product.store';
import { TPartCombination } from '@mf/common/store/product/product.types';
import { useCallback, useMemo } from 'react';

interface BlockedOptions {
  [partId: string]: {
    [attributeKey: string]: string[];
  };
}

interface ValidCustomOptionProps {
  customOption: TCustomOption;
  attribute: string;
}

interface AddBlockedOptionsProps {
  blockedOptionsMap: BlockedOptions;
  partId: string;
  values: string[];
  attributeKey: string;
}

interface RemoveOptionAttributeProps {
  partId: string;
  attribute: string;
}

interface UpdateCustomCombinationProps {
  partId: string;
  newOptions: TPartCombination['options'];
}

export const useDrawerProductConfigHooks = ({
  productId,
}: {
  productId: string;
}) => {
  const {
    customPageValue,
    customFormatValue,
    customCombination,
    setCustomCombination,
    stepsOption,
    setStepsOption,
    currentStep,
    parts,
  } = useProductStore();

  const { useProductDenyRules } = productRules({
    baseUrl: '/api',
  });

  const { data: denyRules } = useProductDenyRules({
    productId: productId,
  });

  const partIds = useMemo(() => {
    return parts.map((part) => part.id);
  }, [parts]);

  // eslint-disable-next-line
  const isNumericArray = (arr: any): arr is number[] => {
    return Array.isArray(arr) && arr.every((item) => typeof item === 'number');
  };

  const validCustomOption = useCallback(
    ({ customOption, attribute }: ValidCustomOptionProps): boolean => {
      if (attribute === 'format') {
        return validFormat({ customOption });
      } else if (attribute === 'page') {
        return validPage({ customOption });
      }

      return false;
    },
    // eslint-disable-next-line
    [customPageValue, customFormatValue],
  );

  const validFormat = ({ customOption }: { customOption: TCustomOption }) => {
    let boolWidth = false;
    let boolHeight = false;

    const { width, height, width_type, height_type } = customOption;

    // validação largura
    if (typeof width === 'number') {
      if (width_type === '>') {
        boolWidth = customFormatValue.width > width;
      } else if (width_type === '<') {
        boolWidth = customFormatValue.width < width;
      }
    } else if (isNumericArray(width)) {
      if (width_type === '=') {
        boolWidth = width.includes(customFormatValue.width);
      }
      if (width_type === '!=') {
        boolWidth = !width.includes(customFormatValue.width);
      }
    }

    // validação altura
    if (typeof height === 'number') {
      if (height_type === '>') {
        boolHeight = customFormatValue.height > height;
      } else if (height_type === '<') {
        boolHeight = customFormatValue.height < height;
      }
    } else if (isNumericArray(height)) {
      if (height_type === '=') {
        boolHeight = height.includes(customFormatValue.height);
      }
      if (height_type === '!=') {
        boolHeight = !height.includes(customFormatValue.height);
      }
    }

    return boolWidth && boolHeight;
  };

  const validPage = ({ customOption }: { customOption: TCustomOption }) => {
    const { id, type } = customOption;

    if (typeof id === 'number') {
      if (type === '>') {
        return customPageValue.pages > id;
      } else if (type === '<') {
        return customPageValue.pages < id;
      }
    } else if (isNumericArray(id)) {
      if (type === '=') {
        return id.includes(customPageValue.pages);
      }
      if (type === '!=') {
        return !id.includes(customPageValue.pages);
      }
    }

    return false;
  };

  const addBlockedOptions = ({
    blockedOptionsMap,
    partId,
    attributeKey,
    values,
  }: AddBlockedOptionsProps) => {
    // Valida se já existe a parte no objeto
    if (!blockedOptionsMap[partId]) {
      blockedOptionsMap[partId] = {};
    }

    // Valida se já existe o atributo na parte
    if (!blockedOptionsMap[partId][attributeKey]) {
      blockedOptionsMap[partId][attributeKey] = [];
    }

    // Adiciona valores de bloqueio
    blockedOptionsMap[partId][attributeKey].push(...values);
  };

  const removeOptionAttribute = useCallback(
    ({ partId, attribute }: RemoveOptionAttributeProps) => {
      // Encontra o índice da parte correspondente
      const partIndex = customCombination.parts.findIndex(
        (part) => part.id === partId,
      );

      if (partIndex !== -1) {
        const options = customCombination.parts[partIndex].options;
        // Cria uma cópia do objeto customCombination
        const updatedCombination = { ...customCombination };

        if (attribute in options) {
          // Remove o atributo desejado
          delete updatedCombination.parts[partIndex].options[
            attribute as keyof (typeof customCombination.parts)[number]['options']
          ];
        }

        // Atualiza o estado
        setCustomCombination(updatedCombination);
      }

      // Remove também a opçao do estado stepsOption
      const copyStepsOption = { ...stepsOption };
      delete copyStepsOption[currentStep];

      setStepsOption(copyStepsOption);
    },
    [
      customCombination,
      stepsOption,
      currentStep,
      setCustomCombination,
      setStepsOption,
    ],
  );

  const updateCustomCombination = ({
    partId,
    newOptions,
  }: UpdateCustomCombinationProps) => {
    const copyCombination = {
      ...customCombination,
    };

    const partIndex = copyCombination.parts.findIndex(
      (part) => part.id === partId,
    );

    if (partIndex !== -1) {
      // Atualiza item ja existente
      const updatedParts = [...copyCombination.parts];
      updatedParts[partIndex] = {
        ...updatedParts[partIndex],
        options: {
          ...updatedParts[partIndex].options,
          ...newOptions,
        },
      };

      setCustomCombination({
        ...copyCombination,
        parts: updatedParts,
      });
    } else {
      // Adiciona novo item
      setCustomCombination({
        ...copyCombination,
        parts: [...copyCombination.parts, { id: partId, options: newOptions }],
      });
    }
  };

  interface ValidPartOptionsProps {
    optionValue: string | number | null | undefined;
    values: string[];
    partId: string;
    selectPartId: string;
  }

  const validPartOption = ({
    optionValue,
    values,
    partId,
    selectPartId,
  }: ValidPartOptionsProps) => {
    return (
      typeof optionValue === 'string' &&
      (values.includes(optionValue) || values.includes('*')) &&
      selectPartId === partId
    );
  };

  interface DefaultValidOptionProps {
    optionValue: string | number | null | undefined;
    values: string[];
  }

  const defaultValidOption = ({
    optionValue,
    values,
  }: DefaultValidOptionProps) => {
    return (
      typeof optionValue === 'string' &&
      (values.includes(optionValue) || values.includes('*'))
    );
  };

  interface ValidCustomOrDefaultOptionProps {
    optionValue: string | number | null | undefined;
    selectCustomOptions: TProductCustomOptions | undefined;
    attribute: string;
    validAttribute: string;
  }

  const validCustomOrDefaultOption = ({
    selectCustomOptions,
    attribute,
    validAttribute,
    optionValue,
  }: ValidCustomOrDefaultOptionProps) => {
    if (
      selectCustomOptions &&
      selectCustomOptions[validAttribute] &&
      attribute === validAttribute
    ) {
      const customOption = selectCustomOptions[validAttribute];

      if (optionValue && String(optionValue).includes('custom')) {
        return validCustomOption({
          customOption,
          attribute,
        });
      }
    }

    return;
  };

  const checkRulesAndGetBlocked = useCallback((): BlockedOptions => {
    const blockedOptionsMap = {};

    // Percorre todas as partes e seleção de combinações até o momento
    customCombination.parts.forEach((productPartCombination) => {
      denyRules?.forEach((rule) => {
        // Variáveis auxiliáres
        const selectOptions = rule.select?.product_options;
        const selectCustomOptions = rule.select?.product_options_custom;
        const selectPartId = rule.select?.product_part_id;
        const denyOptions = rule.deny?.product_options;
        const denyPartId = rule.deny?.product_part_id;
        let check = false;

        let matchesAllSelects = false;
        if (selectOptions) {
          matchesAllSelects = Object.entries(selectOptions).every(
            ([attribute, values]) => {
              const optionValue =
                productPartCombination.options[
                  attribute as keyof typeof productPartCombination.options
                ];

              const validPage = validCustomOrDefaultOption({
                optionValue,
                selectCustomOptions,
                attribute,
                validAttribute: 'page',
              });

              const validFormat = validCustomOrDefaultOption({
                optionValue,
                selectCustomOptions,
                attribute,
                validAttribute: 'format',
              });

              // Verifica se há especificação de parte atual de seleçao
              if (selectPartId) {
                const partOption = validPartOption({
                  optionValue,
                  values,
                  selectPartId,
                  partId: productPartCombination.id,
                });

                if (typeof validPage !== 'undefined') {
                  return partOption || validPage;
                }

                if (typeof validFormat !== 'undefined') {
                  return partOption || validFormat;
                }

                return partOption;
              }

              // Verifica se os atributos selecionados estão incluidos nas opções de seleção
              const defaultOption = defaultValidOption({
                optionValue,
                values,
              });

              if (typeof validPage !== 'undefined') {
                return defaultOption || validPage;
              }

              if (typeof validFormat !== 'undefined') {
                return defaultOption || validFormat;
              }

              return defaultOption;
            },
          );
        }

        let matchesAllCustomSelects = false;
        if (selectCustomOptions) {
          matchesAllCustomSelects = Object.entries(selectCustomOptions).every(
            ([attribute, customOption]) => {
              // Somente 'page' e 'format' possuem opções personalizadas
              if (['page', 'format'].includes(attribute)) {
                if (selectOptions) {
                  if (selectOptions['format']) {
                    return true;
                  }

                  if (selectOptions['page']) {
                    return true;
                  }
                }

                const optionValue =
                  productPartCombination.options[
                    attribute as keyof typeof productPartCombination.options
                  ];

                if (optionValue && String(optionValue).includes('custom')) {
                  return validCustomOption({ customOption, attribute });
                }
              }
              return false;
            },
          );
        }

        if (selectOptions && selectCustomOptions) {
          check = matchesAllSelects && matchesAllCustomSelects;
        } else {
          check = matchesAllSelects || matchesAllCustomSelects;
        }

        // Aplica as regras caso de match
        if (denyOptions) {
          if (check) {
            // Caso tenha sido especificado uma parte a ser bloqueada
            if (denyPartId) {
              Object.entries(denyOptions).forEach(([attribute, values]) => {
                addBlockedOptions({
                  blockedOptionsMap,
                  partId: denyPartId,
                  attributeKey: attribute,
                  values,
                });
              });
              // Se não, aplica a todas as partes
            } else {
              Object.entries(denyOptions).forEach(([attribute, values]) => {
                partIds.forEach((partId) => {
                  // Se a seleção for igual a parte atual
                  if (productPartCombination.id === partId) {
                    // verifica se não é o mesmo atributo atual selecionado
                    if (selectOptions) {
                      if (!Object.keys(selectOptions).includes(attribute)) {
                        addBlockedOptions({
                          attributeKey: attribute,
                          blockedOptionsMap,
                          partId,
                          values,
                        });
                      }
                    }
                    // verifica em customizados também
                    else if (selectCustomOptions) {
                      if (
                        !Object.keys(selectCustomOptions).includes(attribute)
                      ) {
                        addBlockedOptions({
                          attributeKey: attribute,

                          blockedOptionsMap,
                          partId,
                          values,
                        });
                      }
                    }
                    // Senão adiciona normalmente
                  } else {
                    addBlockedOptions({
                      blockedOptionsMap,
                      partId,
                      values,
                      attributeKey: attribute,
                    });
                  }
                });
              });
            }
          }
        }
      });
    });

    return blockedOptionsMap;
    // eslint-disable-next-line
  }, [customCombination.parts, denyRules, validCustomOption, partIds]);

  return {
    validCustomOption,
    addBlockedOptions,
    updateCustomCombination,
    checkRulesAndGetBlocked,
    removeOptionAttribute,
    validCustomOrDefaultOption,
    defaultValidOption,
    isNumericArray,
  };
};
