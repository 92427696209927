/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';

import {
  ButtonGroupItemVariant,
  ButtonGroupOrientation,
  ButtonIconHighlightSize,
  ButtonSize,
  CurrencyPosition,
  CurrencySize,
  FixedBarType,
  FsBreadcrumbItem,
  FsButton,
  FsButtonGroup,
  FsButtonGroupItem,
  FsButtonIcon,
  FsButtonIconHighlight,
  FsButtonMini,
  FsCurrency,
  FsFixedBar,
  FsGalleryProduct,
  FsHeading,
  FsInputSelect,
  FsListContent,
  FsLoading,
  FsParagraph,
  FsPreviewVideo,
  FsTextContent,
  FsToast,
  FsTooltip,
  FsTopSection,
  FsTopicIcon,
  HeadingSize,
  HeadingTag,
  ListContentType,
  ListContentVariant,
  ToastType,
  TooltipPosition,
  TopicIconOrientation,
} from '@printi/ds-offset-react-core';
import { useLayout } from '@mf/common/components/Media/Layout';
import { formatCurrency } from '@brazilian-utils/brazilian-utils';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Image from 'next/image';
import { MobileView } from '@mf/common/components/index';
import { Product, useCartStore } from '@mf/common/store/cart';
import { useMutation } from '@tanstack/react-query';
import { useProductStore } from '@mf/common/store/product/product.store';
import {
  TCombination,
  TCombinationQuantity,
  TInvisiblePartOptions,
  TPartCombination,
} from '@mf/common/store/product/product.types';
import axios from 'axios';
import { NotFoundScreen } from '@screens/main/NotFound';
import { PrintModels } from '@/components/main/PrintModels';
import { ModalProductQuantity } from '@/components/main/ModalProductQuantity';
import {
  CustomProperties,
  DrawerProductConfig,
} from '@/components/main/DrawerProductConfig';
import { DrawerCartConfirmation } from '@/components/main/DrawerCartConfirmation';
import productService from '@/services/product';
import {
  ProducAttributeOptionType,
  TAttributeOptions,
  TPartsAttributes,
  TPartsAttributesOptions,
} from '@/services/product/types';
import { CarouselRelatedProducts } from '@/components/main/CarouselRelatedProducts';
// eslint-disable-next-line import/order
import {
  DesktopView,
  InfoContainer,
  StickyComponent,
  StyledBreadcrumb,
  StyledContainer,
  StyledFixedBar,
  StyledFsContainer,
  StyledGalleryProduct,
  StyledPopover,
  StyledPopoverContent,
  StyledPopoverHeader,
  StyledRow,
  StyledSection,
  TextOverlay,
  ToastButtonArea,
} from './Product.styles';
import { ModalProductFreight } from '@/components/main/ModalProductFreight';
import { FreightList } from '@/components/main/FreightList/FreightList';
import { HistogramRate } from '@/components/main/TrustvoxReviews';
import { TemplateDownload } from '@/components/main/TemplateDownload';
import popoverGif from '@/assets/add-to-cart-popover.gif';
import { SERVER_ERROR_STATUS } from '@/constants/error-status';

interface ProductScreenParams {
  slug: string;
}

type ProducImageType = {
  src?: string;
  alt?: string | null;
};

type Rate = {
  average: number;
  best: number;
  count: number;
  histogram: object;
};

export type ProductData = {
  slug: string;
  name?: string;
  image?: string;
  description?: string;
  description2?: string;
  id: string;
};

export function ProductScreen({ slug }: ProductScreenParams) {
  const { isMobile, setShowSearchButton } = useLayout();
  const ref = useRef<HTMLInputElement>(null);
  const [isInView, setIsInView] = useState(false);
  const [popoverOpened, setPopoverOpened] = useState<boolean>(false);
  const [openCartConfirmation, setOpenCartConfirmation] =
    useState<boolean>(false);
  const [openQtt, setOpenQtt] = useState<boolean>(false);
  const [videoVisible, setVideoVisible] = useState<boolean>(false);

  const [openFreight, setOpenFreight] = useState<boolean>(false);
  const [quantityError, setQuantityError] = useState<string>('');

  const [description, setDescription] = useState<string>('');
  const [showMore, setShowMore] = useState<boolean>(false);
  const [showMoreButton, setShowMoreButton] = useState<boolean>(false);
  const showMoreRef = useRef<HTMLDivElement>(null);
  const [starsCount, setStarsCount] = useState<Rate>({
    average: 0,
    best: 0,
    count: 0,
    histogram: {},
  });

  setShowSearchButton(true);

  const [autoSelectAttributes, setAutoSelectAttributes] =
    useState<TCombination>({
      id: 'custom',
      name: 'Configure do seu jeito',
      is_visible: 1,
      parts: [],
    });

  const [invisible, setInvisible] = useState<TInvisiblePartOptions>({});

  const { addToCart, setRelatedProducts } = useCartStore();
  const {
    quantity,
    updateQuantity,
    setParts,
    printModelCombination,
    freightOptions,
    setFreightOptions,
    address,
    setAddress,
    customCombinations,
    customCombination: drawerCustomCombination,
    setPrintModelCombination,
    setInvisiblePartsOptions,
    setCustomCombination,
    quantitiesOptions,
    setQuantitiesOptions,
    toastOpen,
    popoverOpen,
    openConfig,
    setPopoverOpen,
    setOpenConfig,
    setToastOpen,
    isSingleFormat,
    setIsSingleFormat,
  } = useProductStore();

  const auth = {
    baseUrl: process.env.BFF_API_BASE_URL,
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const findQuantity = (target: any) => {
    const index = target.index;
    updateQuantity(quantitiesOptions[index]);
  };

  const checkInView = () => {
    if (ref.current !== null) {
      const rect = ref.current.getBoundingClientRect();
      setIsInView(rect.top < window.innerHeight && rect.bottom >= 0);
    }
  };

  useEffect(() => {
    setAddress(null);
    setFreightOptions([]);
  }, [setAddress, setFreightOptions]);

  useEffect(() => {
    checkInView();
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', checkInView);
    return () => {
      document.removeEventListener('scroll', checkInView);
    };
  }, []);

  const { useProduct, getProductQuantity } = productService(auth);
  const { data, status } = useProduct({ slug });

  const productPriceCombinationEconomic = data?.default_combinations.filter(
    (combination: { name: string }) => combination.name === 'Econômica',
  )[0]?.quantities[0];

  const productPriceFromCMS = data?.min_price?.value;

  const price = productPriceCombinationEconomic
    ? productPriceCombinationEconomic.price
    : productPriceFromCMS;

  const STORE_ID = String(process.env.TRUSTVOX_STORE_ID);
  const TOKEN = String(process.env.TRUSTVOX_TOKEN);

  const API_URL = String(process.env.TRUSTVOX_WIDGET_URL).concat(
    '?',
    new URLSearchParams({
      code: data?.final_product_id || '',
      store_id: STORE_ID,
    }).toString(),
  );

  const fetchStars = async () => {
    await axios
      .get(API_URL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/vnd.trustvox-v2+json',
          Authorization: `token ${TOKEN}`,
        },
      })
      .then(function (response) {
        setStarsCount(() => ({
          ...response.data.rate,
        }));
      })
      .catch(function (error) {
        // eslint-disable-next-line
        console.error(error);
      });
  };

  useEffect(() => {
    const popoverOpened = localStorage.getItem('popoverOpened') === 'true';

    setPopoverOpened(popoverOpened);
  }, [setPopoverOpened]);

  useEffect(() => {
    if (data) {
      fetchStars();
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'view_item',
        event_name: 'view_item',
        ecommerce: {
          currency: 'BRL',
          items: [
            {
              item_id: data.final_product_id,
              item_name: data.name,
              affiliation: 'Printi',
              discount: 0,
              index: 0,
              item_brand: 'Printi',
              item_category: '',
              price: data.min_price?.value,
              quantity: data.min_price?.quantity,
            },
          ],
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const truncateDescription = useCallback(() => {
    const container = showMoreRef.current;
    if (container) {
      const text = data?.long_description || '';
      let index = text.length;
      let currentText = text;

      while (container.scrollHeight > 106 && index > 0) {
        index -= 1;
        currentText = text.slice(0, index) + '...';
        container.innerText = currentText;
      }

      if (!currentText) {
        return;
      }

      setDescription(currentText);
    }
  }, [data?.long_description]);

  useEffect(() => {
    if (showMoreRef.current) {
      const maxHeight = 106;
      if (showMoreRef.current.scrollHeight > maxHeight) {
        setShowMoreButton(true);
      }
    }
  }, [description]);

  useEffect(() => {
    if (showMoreButton) {
      truncateDescription();
    }
  }, [showMoreButton, truncateDescription]);

  useEffect(() => {
    setDescription(data?.long_description || '');
  }, [data?.long_description]);

  useEffect(() => {
    if (showMore) {
      setDescription(data?.long_description || '');
    } else {
      truncateDescription();
    }
  }, [showMore, data?.long_description, truncateDescription]);

  const { mutate: mutateProductQuantity, isLoading } = useMutation(
    getProductQuantity,
    {
      onSuccess: (data) => {
        if (!data.length) {
          return;
        }

        setQuantitiesOptions(data);
        updateQuantity(data[0]);
      },
      onError: (error) => {
        // eslint-disable-next-line
        console.error(error);
      },
    },
  );

  const microData: ProductData = {
    slug: slug,
    name: data?.name,
    image: data?.small_image.url || '',
    id: data?.final_product_id || '',
  };

  const updateAutoSelectAttributes = ({
    partId,
    newOptions,
  }: {
    partId: string;
    newOptions: TPartCombination['options'];
  }) => {
    setAutoSelectAttributes((prevState) => {
      const partIndex = prevState.parts.findIndex((part) => part.id === partId);

      if (partIndex !== -1) {
        // Atualiza item já existente
        const updatedParts = [...prevState.parts];
        updatedParts[partIndex] = {
          ...updatedParts[partIndex],
          options: {
            ...updatedParts[partIndex].options,
            ...newOptions,
          },
        };

        return {
          ...prevState,
          parts: updatedParts,
        };
      } else {
        // Adiciona novo item
        return {
          ...prevState,
          parts: [...prevState.parts, { id: partId, options: newOptions }],
        };
      }
    });
  };

  useEffect(() => {
    setCustomCombination({
      id: 'custom',
      name: 'Configure do seu jeito',
      is_visible: 1,
      parts: [],
    });
  }, [setCustomCombination]);

  useEffect(() => {
    if (autoSelectAttributes.parts.length) {
      setCustomCombination(autoSelectAttributes);
    }
  }, [autoSelectAttributes, setCustomCombination]);

  const checkAutoSelectInvisibleCustomOptions = useCallback(() => {
    if (!data?.parts.length) {
      return;
    }

    data?.parts?.forEach((part) => {
      part.attributes.forEach((attribute) => {
        const attributeOptions =
          part.attributes_options[attribute.id as keyof TAttributeOptions];

        // se visible = false, e tiver uma unica opção de seleção, auto seleciona
        if (attributeOptions && !attribute.visible) {
          if (attributeOptions.length === 1) {
            updateAutoSelectAttributes({
              partId: part.id,
              newOptions: {
                [attribute.id]: attributeOptions[0].id,
              },
            });

            // caso tenha mais opções de seleção, guarda num estado pra validar nas regras (haverá um momento em que sobrará apenas uma opção)
          } else if (attributeOptions.length > 1) {
            setInvisible((prev) => ({
              ...prev, // Mantém o estado anterior
              [part.id]: {
                ...prev[part.id], // Mantém as opções anteriores do part.id atual
                [attribute.id]: attributeOptions, // Adiciona ou atualiza as novas opções
              },
            }));
          }
        }
      });
    });

    // eslint-disable-next-line
  }, [data?.parts]);

  useEffect(() => {
    if (!invisible) {
      return;
    }
    setInvisiblePartsOptions(invisible);
  }, [invisible, setInvisiblePartsOptions]);

  useEffect(() => {
    checkAutoSelectInvisibleCustomOptions();
  }, [checkAutoSelectInvisibleCustomOptions]);

  useEffect(() => {
    if (drawerCustomCombination?.parts?.length === 0) {
      checkAutoSelectInvisibleCustomOptions();
    }
  }, [drawerCustomCombination, checkAutoSelectInvisibleCustomOptions]);

  //TODO make this vars in context or zustand (without local storage)
  const attributes = useMemo(() => {
    return data?.parts?.flatMap((part) => part.attributes) || [];
  }, [data]);

  const filterVisible = useCallback(
    (attributes: TPartsAttributes[] | undefined) => {
      return (
        attributes?.filter((atrb) => {
          if (atrb.id === 'format' || atrb.id === 'page') {
            return atrb.visible == 1;
          } else {
            return (
              atrb.visible == 1 &&
              data?.parts.filter(
                (pt) =>
                  pt.attributes_options[atrb.id as keyof TAttributeOptions]
                    ?.length,
              ).length
            );
          }
        }) || []
      );
    },
    [data?.parts],
  );

  const visibleAttributes = useMemo(() => {
    return filterVisible(
      data?.parts?.flatMap((part) =>
        part.attributes.map((attribute) => {
          return {
            ...attribute,
            partId: part.id,
            partName: part.name,
          };
        }),
      ),
    );
  }, [data?.parts, filterVisible]);

  const visibleAttributesOptions = useMemo(
    () =>
      data?.parts?.flatMap((part) =>
        filterVisible(part.attributes).map((attribute) => {
          const id = attribute.id as keyof TAttributeOptions;
          const attributeOption = part.attributes_options?.[id];

          const object = attributeOption?.map((option) => {
            return {
              ...option,
              partName: part.name,
              attribute: id,
              partId: part.id,
            };
          });

          return object;
        }),
      ),
    [data?.parts, filterVisible],
  ) as unknown as TPartsAttributesOptions[][] | undefined;

  const getQuantityString = (quantity: TCombinationQuantity) => {
    return `${quantity.quantity} unid. / R$ ${formatCurrency(quantity.price)}`;
  };

  const addItemToCart = () => {
    if (!data || !printModelCombination) {
      return;
    }
    setRelatedProducts(slug, relatedProducts || []);

    const product: Product = {
      ...data,
      selectedQuantity: quantity,
      selectedCombination: printModelCombination,
    };

    addToCart(product);
    setOpenCartConfirmation(true);
    window.dataLayer.push({
      event: 'genericEvent',
      event_name: 'preview_cart_drawer',
    });

    window?.dataLayer?.push({ ecommerce: null });
    window?.dataLayer?.push({
      event: 'add_to_cart',
      event_name: 'add_to_cart',
      ecommerce: {
        currency: 'BRL',
        value: parseFloat(product.selectedQuantity.price.toFixed(2)),
        items: [
          {
            item_id: product.final_product_id,
            item_name: product.name,
            affiliation: 'Printi',
            coupon: '',
            discount: 0,
            index: 0,
            item_brand: 'Printi',
            item_category: '',
            item_variant: product.selectedCombination.name,
            location_id: '',
            price: parseFloat(product.selectedQuantity.price.toFixed(2)),
            quantity: product.selectedQuantity.quantity,
          },
        ],
      },
    });
  };

  const onSimulateFreight = () => {
    if (!quantity.quantity) {
      setQuantityError(
        'Por favor, selecione uma quantidade válida para o produto',
      );
    } else {
      setQuantityError('');
      setOpenFreight(true);
      window.dataLayer.push({
        event: 'genericEvent',
        event_name: 'start_shipping_simulation',
      });
    }
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    setParts(data.parts);
  }, [data, setParts]);

  useEffect(() => {
    if (printModelCombination?.quantities?.length) {
      setQuantitiesOptions(printModelCombination?.quantities);
      if (printModelCombination?.quantities[0]) {
        updateQuantity(printModelCombination?.quantities[0]);
      }
    } else {
      setQuantitiesOptions([]);
      updateQuantity({ quantity: 0, price: 0, unit_price: 0, token: '' });
    }
  }, [printModelCombination, setQuantitiesOptions, updateQuantity]);

  useEffect(() => {
    if (printModelCombination?.id !== 'custom' || !data?.final_product_id) {
      return;
    }

    if (printModelCombination?.parts[0]?.id !== data?.parts[0]?.id) {
      return;
    }

    const product_parts = printModelCombination?.parts || [];
    const payload = {
      final_product_id: data?.final_product_id,
      product_parts,
    };

    mutateProductQuantity(payload);
  }, [
    printModelCombination,
    data?.final_product_id,
    mutateProductQuantity,
    data?.parts,
  ]);

  const relatedProducts = useMemo(() => {
    return data?.cross_selling.map((product) => {
      return {
        slug: product.slug,
        small_image: product.small_image,
        small_image_hover: product.small_image_hover,
        minimal_price: product.minimal_price,
        name: product.name,
        minimal_quantity: product.minimal_quantity,
      };
    });
  }, [data?.cross_selling]);

  const customCombination = useMemo(() => {
    const custom = customCombinations[slug];

    if (!custom) {
      return null;
    }

    return custom;
  }, [customCombinations, slug]);

  const customProperties = useMemo(() => {
    return (
      data?.parts.reduce((acc, part) => {
        acc[part.id] = {
          customPage: part.custom_page,
          customFormat: part.custom_format,
        };
        return acc;
      }, {} as CustomProperties) || {}
    );
  }, [data?.parts]);

  const hasFreightOptions = !!freightOptions.length;
  const hasDefaultCombinations = !!data?.default_combinations.length;
  const hasCombination = hasDefaultCombinations || !!customCombination;
  const hasRelatedProducts = !!relatedProducts?.length;

  useEffect(() => {
    if (!hasCombination) {
      setPrintModelCombination(null);
    }
  }, [hasCombination, setPrintModelCombination]);

  useEffect(() => {
    if (!data?.default_combinations) {
      return;
    }

    if (data?.default_combinations[0]?.name === 'Formato único') {
      setIsSingleFormat(true);
    } else {
      setIsSingleFormat(false);
    }
  }, [data?.default_combinations, setIsSingleFormat]);

  if (status === 'error') {
    return <NotFoundScreen errorState={SERVER_ERROR_STATUS} />;
  }

  function galleryImages(): any[] {
    if (!data?.banner?.images) {
      return [];
    }

    const images = data?.banner?.images?.map(
      (image: ProducImageType | undefined, index: number) => {
        return {
          index: index + 1,
          full: <Image src={image?.src || ''} alt={image?.alt || ''} fill />,
          thumb: (
            <Image
              src={
                image?.src ||
                'https://d1br4h274rc9sc.cloudfront.net/content/paper_b2591f4c2b.png'
              }
              alt={image?.alt || ''}
              fill
            />
          ),
        } as any;
      },
    );

    return images;
  }

  return (
    <>
      <FsPreviewVideo
        src={data?.banner?.video?.url}
        avatarSrc="./assets/images/avatar.png"
        showButton={true}
        showTestimony={false}
        showOverlay={true}
        name={data?.name}
        visible={videoVisible}
        onClose={() => setVideoVisible(false)}
        style={{
          zIndex: '9999 !important',
        }}
      />
      <StyledFsContainer>
        <DesktopView>
          <StyledBreadcrumb>
            <FsBreadcrumbItem href="/">Início</FsBreadcrumbItem>
            <FsBreadcrumbItem href={`/${slug}/`}>{data?.name}</FsBreadcrumbItem>
          </StyledBreadcrumb>
        </DesktopView>
        <StyledGalleryProduct>
          <FsGalleryProduct
            images={galleryImages() as unknown as any[]}
            fullImageHeight={450}
            onVideoThumbnailClick={() => {
              setVideoVisible(true);
              window.dataLayer.push({
                event: 'genericEvent',
                event_name: 'video_clicked',
                event_parameter_1: data?.final_product_id,
                event_parameter_2: data?.name,
              });
            }}
          ></FsGalleryProduct>
        </StyledGalleryProduct>
        <StyledSection className="fs-row">
          <div className="fs-col-md-7 first-column">
            <FsTextContent
              heading={data?.name}
              paragraph={''}
              showRateStatus
              rating={starsCount.average}
              ratingDescription={`${starsCount.count} avaliações`}
              headingProps={{ tag: HeadingTag.H1 }}
              onClick={() => (window.location.href = '#avaliacoes')}
            />
            <div
              style={{
                position: 'relative',
                maxWidth: '550px',
                cursor: showMoreButton ? 'pointer' : 'auto',
              }}
              onClick={() => {
                if (showMoreButton) {
                  setShowMore(!showMore);
                }
              }}
            >
              <FsParagraph
                ref={showMoreRef}
                style={{
                  maxHeight: showMore ? 'none' : '106px',
                  overflow: 'hidden',
                  marginTop: isMobile ? '0px' : '-65px',
                  marginBottom:
                    !showMore && !showMoreButton ? 'var(--spacing-6x)' : '0px',
                }}
              >
                {description || data?.long_description}
              </FsParagraph>
              {!showMore && showMoreButton && <TextOverlay />}
            </div>
            {showMoreButton && (
              <FsButtonMini
                onClick={() => setShowMore(!showMore)}
                style={{
                  marginBottom: isMobile ? '0px' : 'var(--spacing-8x)',
                  marginTop: 'var(--spacing-1x)',
                }}
              >
                {showMore ? 'Exibir menos' : 'Exibir mais'}
              </FsButtonMini>
            )}
            <DesktopView>
              {hasCombination && (
                <PrintModels
                  defaultCombinations={data?.default_combinations}
                  productParts={data?.parts}
                  slug={slug}
                />
              )}
            </DesktopView>
          </div>
          <div className="fs-col-md-5">
            <StickyComponent>
              <StyledContainer>
                <FsParagraph>Valor total</FsParagraph>
                <StyledRow
                  className="price-spacing"
                  ref={ref}
                  style={{
                    justifyContent: quantity?.price ? 'center' : 'flex-start',
                  }}
                >
                  {isLoading ? (
                    <FsLoading />
                  ) : (
                    <>
                      <FsHeading size={HeadingSize.MD} tag={HeadingTag.SPAN}>
                        R$
                        {quantity?.price
                          ? formatCurrency(quantity.price || 0)
                          : formatCurrency(price || 0)}
                      </FsHeading>
                      {!!quantity.unit_price && (
                        <FsCurrency
                          size={CurrencySize.SM}
                          price={
                            quantity?.unit_price
                              ? formatCurrency(quantity.unit_price || 0, {
                                  precision: 2,
                                })
                              : formatCurrency(data?.min_price?.quantity || 0)
                          }
                          description="/unid."
                          descriptionPosition={CurrencyPosition.Right}
                        />
                      )}
                    </>
                  )}
                </StyledRow>
                {!!quantitiesOptions.length && (
                  <>
                    <FsParagraph className="quantity-label">
                      Quantidade
                    </FsParagraph>
                    <div className="quantity-box">
                      <FsInputSelect
                        id="item-qtt"
                        label=""
                        placeholder="Selecione a quantidade"
                        className="input-select"
                        onChange={(e) => {
                          findQuantity(e.target);
                        }}
                        value={getQuantityString(quantity)}
                        supportText={quantityError}
                        error={!!quantityError}
                      >
                        {quantitiesOptions.map((item) => (
                          <React.Fragment key={item.token}>
                            {getQuantityString(item)}
                          </React.Fragment>
                        ))}
                      </FsInputSelect>
                      {data?.custom_quantity.allow && (
                        <FsTooltip
                          position={
                            isMobile
                              ? TooltipPosition.Left
                              : TooltipPosition.Top
                          }
                          label="Outras quantidades"
                        >
                          <FsButtonIconHighlight
                            icon="PlusLine"
                            size={ButtonIconHighlightSize.LG}
                            onClick={() => setOpenQtt(true)}
                          />
                        </FsTooltip>
                      )}
                    </div>
                  </>
                )}

                {attributes?.map((attribute, key) => {
                  const id = attribute.id as keyof TAttributeOptions;
                  if (attribute.configurable) {
                    return (
                      <FsInputSelect
                        key={id}
                        id={id}
                        label={attribute.name}
                        placeholder="Selecione"
                        className="input-select"
                      >
                        {data?.parts[key].attributes_options?.[id]?.map(
                          (option: ProducAttributeOptionType) => {
                            return <div key={option.id}>{option.name}</div>;
                          },
                        )}
                      </FsInputSelect>
                    );
                  }
                })}
                <ToastButtonArea>
                  {!hasCombination ? (
                    <FsTooltip
                      label="Configure Primeiro"
                      position={TooltipPosition.Top}
                    >
                      <FsButton
                        size={ButtonSize.LG}
                        icon="CheckoutAddLine"
                        ariaLabel="Adicionar ao carrinho"
                        className="fullButtons"
                        onClick={addItemToCart}
                        disabled={!hasCombination}
                      >
                        Adicionar ao carrinho
                      </FsButton>
                    </FsTooltip>
                  ) : (
                    <FsButton
                      size={ButtonSize.LG}
                      icon="CheckoutAddLine"
                      ariaLabel="Adicionar ao carrinho"
                      className="fullButtons"
                      onClick={addItemToCart}
                      disabled={!hasCombination}
                    >
                      Adicionar ao carrinho
                    </FsButton>
                  )}
                </ToastButtonArea>
              </StyledContainer>

              {hasFreightOptions ? (
                <FreightList
                  address={address}
                  freightOptions={freightOptions}
                  setOpenFreight={setOpenFreight}
                />
              ) : (
                <FsListContent
                  type={ListContentType.IconShape}
                  variant={ListContentVariant.Outline}
                  lastItem={true}
                  icon="DeliveryLine"
                  showHeading={false}
                  showButton={true}
                  buttonLabel="Simular"
                  onButtonClick={onSimulateFreight}
                  showLabelLeft={true}
                  labelLeft={`Simule o frete para a sua região.`}
                  showTag={false}
                  labelRight=""
                  description=""
                />
              )}
              {!isMobile && hasCombination && (
                <TemplateDownload finalProductId={data?.final_product_id} />
              )}
            </StickyComponent>
            <MobileView>
              {hasCombination && typeof window !== 'undefined' && (
                <>
                  <PrintModels
                    defaultCombinations={data?.default_combinations}
                    productParts={data?.parts}
                    slug={slug}
                  />
                  <TemplateDownload finalProductId={data?.final_product_id} />
                </>
              )}
            </MobileView>
          </div>
        </StyledSection>
        {hasRelatedProducts && (
          <CarouselRelatedProducts
            heading="Você também pode gostar"
            cards={relatedProducts || []}
          />
        )}
        <HistogramRate
          _productId={data?.final_product_id}
          starsCount={starsCount}
        />
        <StyledSection>
          <FsTopSection
            headingProps={{
              size: HeadingSize.SM,
              tag: HeadingTag.H2,
            }}
            heading="Como é comprar na Printi?"
            description=""
          />
          <InfoContainer>
            <FsTopicIcon
              orientation={TopicIconOrientation.Vertical}
              headingProps={{
                children: 'Escolha seus produtos',
                tag: HeadingTag.H3,
              }}
              icon="CheckoutLine"
              paragraph="Adicione todos os produtos no carrinho, você pode configurar formato, material e etc."
            />
            <FsTopicIcon
              orientation={TopicIconOrientation.Vertical}
              headingProps={{
                children: 'Conclua a compra',
                tag: HeadingTag.H3,
              }}
              icon="CheckLine"
              paragraph="Escolha a forma de entrega e pagamento que preferir, depois é só finalizar a sua compra."
            />
            <FsTopicIcon
              orientation={TopicIconOrientation.Vertical}
              headingProps={{
                children: 'Personalize seu pedido',
                tag: HeadingTag.H3,
              }}
              icon="ArtLine"
              paragraph="Você pode fazer o upload da sua arte, contratar um designer Printi ou experimentar a Printi AI."
            />
            <FsTopicIcon
              orientation={TopicIconOrientation.Vertical}
              headingProps={{
                children: 'Receba e avalie seu pedido',
                tag: HeadingTag.H3,
              }}
              icon="RateLine"
              paragraph={
                <>
                  Acompanhe seu pedido por e-mail ou através do seu perfil. E
                  conte com suporte por{' '}
                  <a
                    href="https://api.whatsapp.com/send?phone=551141183816"
                    target="_blank"
                    style={{
                      textDecoration: 'underline',
                    }}
                  >
                    Whatsapp
                  </a>
                </>
              }
            />
          </InfoContainer>
        </StyledSection>
        {isMobile && (
          <StyledFixedBar
            id="fixed-bar"
            visible={!isInView && !openConfig && !openCartConfirmation}
          >
            <FsFixedBar
              type={FixedBarType.Price}
              showContentSlot={true}
              showActionSlot={true}
              showDescription={true}
              contentSlot={
                <FsCurrency
                  price={`R$ ${formatCurrency(quantity?.price || 0)}`}
                  size={CurrencySize.SM}
                  description={`${quantity?.quantity || 0} /unid.`}
                  descriptionPosition={CurrencyPosition.Bottom}
                />
              }
              actionSlot={
                <FsButtonGroup
                  orientation={ButtonGroupOrientation.Horizontal}
                  size="sm"
                >
                  {!hasCombination ? (
                    <FsTooltip
                      position={TooltipPosition.Left}
                      label="Configure primeiro"
                      slot="primary"
                    >
                      <FsButtonGroupItem
                        key={0}
                        slot="primary"
                        variant={ButtonGroupItemVariant.Icon}
                        buttonIconProps={{
                          icon: 'CheckoutAddLine',
                          inverse: true,
                          disabled: !hasCombination,
                        }}
                        onClick={addItemToCart}
                      >
                        Button
                      </FsButtonGroupItem>
                    </FsTooltip>
                  ) : (
                    <FsButtonGroupItem
                      key={0}
                      slot="primary"
                      variant={ButtonGroupItemVariant.Icon}
                      buttonIconProps={{
                        icon: 'CheckoutAddLine',
                        inverse: true,
                        disabled: !hasCombination,
                      }}
                      onClick={addItemToCart}
                    >
                      Button
                    </FsButtonGroupItem>
                  )}

                  {!isSingleFormat ? (
                    <FsButtonGroupItem
                      key={1}
                      slot="secondary"
                      variant={ButtonGroupItemVariant.Icon}
                      buttonIconProps={{ icon: 'EditLine' }}
                      onClick={() => setOpenConfig(true)}
                    >
                      Button
                    </FsButtonGroupItem>
                  ) : (
                    <></>
                  )}
                </FsButtonGroup>
              }
            />
          </StyledFixedBar>
        )}
      </StyledFsContainer>
      <DrawerProductConfig
        isOpen={openConfig}
        visibleAttributes={visibleAttributes}
        visibleAttributesOptions={visibleAttributesOptions || []}
        customProperties={customProperties}
        data={microData}
      />
      <DrawerCartConfirmation
        productSlug={slug}
        isOpen={openCartConfirmation}
        setOpenConfig={setOpenCartConfirmation}
      />
      <ModalProductQuantity
        isOpen={openQtt}
        setOpenQtt={setOpenQtt}
        custom_quantity={data?.custom_quantity}
        final_product_id={data?.final_product_id}
      />
      <ModalProductFreight
        productId={data?.final_product_id || ''}
        isOpen={openFreight}
        setOpen={setOpenFreight}
      />

      <FsToast
        type={ToastType.Negative}
        opened={toastOpen && !isSingleFormat}
        showButton={false}
        onButtonClick={() => setToastOpen(false)}
      >
        Produto configurado do seu jeito.
      </FsToast>

      {!isMobile && popoverOpen && !popoverOpened && (
        <StyledPopover>
          <StyledPopoverHeader>
            <FsHeading size={HeadingSize['2XS']} tag={HeadingTag.H3}>
              Agora que você configurou o produto, <br /> adicione ao carrinho!
            </FsHeading>
            <FsButtonIcon
              icon="CloseLine"
              onClick={() => {
                setPopoverOpen(false);
                localStorage.setItem('popoverOpened', 'true');
              }}
            />
          </StyledPopoverHeader>
          <StyledPopoverContent>
            <Image
              alt="Como adicionar o produto ao carrinho"
              src={popoverGif.src}
              fill={true}
            />
          </StyledPopoverContent>
        </StyledPopover>
      )}
    </>
  );
}
