import { media } from '@mf/common/utils/styles/index';
import styled from 'styled-components';
import { FsButtonGroup, FsDrawer } from '@printi/ds-offset-react-core';

export const StyledDrawer = styled(FsDrawer)`
  .no-content {
    display: flex;
    justify-content: center;

    > div {
      width: 100%;
    }
  }

  .drawer-base-config__button-group {
    ${media.lessThan('desktop')} {
      justify-content: space-between !important;
    }

    ${media.greaterThan('desktop')} {
      justify-content: flex-end !important;
    }
  }
`;

export const Content = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: auto;
  height: 100%;
  gap: var(--spacing-4x);
`;

export const TopSectionCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CarouselWrapper = styled.div`
  width: auto;
  overflow: hidden;
  flex-shrink: 0;
`;

export const CarouselContent = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-2x);
  overflow-x: auto;

  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${media.greaterThan('touch')} {
    gap: var(--spacing-3x);
  }
`;

export const CardWrapper = styled.div`
  min-width: 140px;
  max-width: min-content;
  flex: 0 0 auto;

  @media screen and (min-width: 500px) {
    flex: unset;
    max-width: none;
  }
`;

export const ButtonGroup = styled(FsButtonGroup)`
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-2x);
`;
